import React, { ReactNode } from 'react';
import _ from 'lodash';
// nodejs library to set properties for components
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

let styles: IStyles = {
    grid: {
        margin: '0 -15px !important',
        width: 'unset'
    }
};

interface IStyles {
    grid: IGrid;
}

interface IGrid {
    margin: string;
    width: string;
	padding?: string;
	[key: string]: any
}

export default function GridContainer(props: IProps) {
    const { children, padding, ...rest } = props;

    if (!_.isNil(padding)) {
        styles.grid.padding = padding;
    }

    const useStyles = makeStyles(styles);
    const classes = useStyles();
    return (
        <Grid container {...rest} className={classes.grid}>
            {children}
        </Grid>
    );
}

interface IProps {
    children: ReactNode;
    padding?: string;
    [key: string]: any;
}

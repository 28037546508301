import { ChartDataSets } from 'chart.js';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { IDirectorInformation } from '../../../models/OverviewModel';
import useTimeline from '../Timeline/useTimeline';

const DirectorTimeline: React.FunctionComponent<IProps> = (props) => {
    const [graphData, setDirectorGraphData] = useState<any>({});
    const [graphLabels, setLabels] = useState<string[]>([]);

    const indexOfApplicant = props.directors.findIndex((director) => director.IsApplicant);

    if (indexOfApplicant !== -1) {
        const applicantFullName = props.directors[indexOfApplicant].FullName;

        if (!applicantFullName.includes('(APPLICANT)')) {
            props.directors[indexOfApplicant].FullName += ' (APPLICANT)';
        }
    }

    const companyRef = useTimeline(graphData, graphLabels, props.directors, props.minimumDate);

    let height = 300;

    if (props.directors.length <= 6) {
        height = 100;
    }

    useEffect(() => {
        const labels: string[] = [];
        const dataSets: ChartDataSets[] = [];
        const groupedByStatus = _.groupBy(
            props.directors.filter((director) => director.Status !== ''),
            (o) => o.Status
        );

        const backgroundColors = ['#003f5c', '#ff6361', '#ffa600'];
        let index = 0;
        let counter = 0;

        _.forEach(groupedByStatus, (obj, key) => {
            const backgroundColor = backgroundColors[index];

            if (index === backgroundColors.length - 1) {
                index = 0;
            } else {
                index += 1;
            }

            const dataSet: ChartDataSets = {
                data: new Array(props.directors.length).fill(undefined),
                label: key,
                backgroundColor: backgroundColor
            };

            for (let i = 0; i < obj.length; i += 1) {
                const director = obj[i];

                labels.push(director.FullName);

                (dataSet.data as any[])[counter] = [
                    dateFns.parse(director.StartDate, 'dd-MM-yyyy', new Date()),
                    dateFns.parse(director.EndDate, 'dd-MM-yyyy', new Date())
                ];

                counter += 1;
            }

            dataSets.push(dataSet);
        });

        setDirectorGraphData(dataSets);
        setLabels(labels);
    }, [props.directors, props.minimumDate]);

    return <canvas height={height} ref={companyRef}></canvas>;
};

interface IProps {
    minimumDate?: Date;
    directors: IDirectorInformation[];
}

export default DirectorTimeline;

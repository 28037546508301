import React, { ReactNode } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import styles from '../../assets/jss/material-dashboard-react/components/buttonStyle';

const useStyles = makeStyles(styles);

export default function RegularButton(props: IProps) {
	const classes = useStyles();
	const {
		color,
		round,
		children,
		disabled,
		simple,
		size,
		block,
		link,
		justIcon,
		className,
		muiClasses,
		...rest
	} = props;
	const btnClasses = classNames({
		[classes.button]: true,
		[classes[size ? size : '']]: size,
		[classes[color? color: '']]: color,
		[classes.round]: round,
		[classes.disabled]: disabled,
		[classes.simple]: simple,
		[classes.block]: block,
		[classes.link]: link,
		[classes.justIcon]: justIcon,
		[className]: className
	});
	return (
		<Button {...rest} classes={muiClasses} className={btnClasses}>
			{children}
		</Button>
	);
}

interface IProps {
	color?:
		| 'primary'
		| 'info'
		| 'success'
		| 'warning'
		| 'danger'
		| 'rose'
		| 'white'
    | 'transparent';
  size?: 'sm' | 'lg';
  simple?: boolean;
  round?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: any;
  muiClasses?: any;
  children?: ReactNode;
  href?: string;
  target?: string;
  fullWidth?: boolean;
  onClick?: any;
}

/**
 * Creates a proxy for an object that allows format-insensitive access to its properties.
 *
 * This proxy enables access to object properties regardless of whether they are
 * formatted in PascalCase or UPPER_SNAKE_CASE, making it particularly useful for
 * handling legacy data with inconsistent property naming conventions.
 *
 * Example usage:
 *
 * ```typescript
 * const obj = {
 *     HiThere: 'example',
 *     HELLO_WORLD: 'example2'
 * };
 * const proxy = createFormatInsensitiveProxy(obj);
 *
 * console.log(proxy.HiThere); // Outputs: 'example'
 * console.log(proxy.HI_THERE); // Outputs: 'example'
 *
 * console.log(proxy.HELLO_WORLD); // Outputs: 'example2'
 * console.log(proxy.HelloWorld); // Outputs: 'example2'
 * ```
 *
 * @param originalObj - The original object to wrap in the proxy.
 * @returns A proxy object that allows format-insensitive property access.
 */
export function createCaseInsensitiveProxy(originalObj: any): any {
    return new Proxy(originalObj, {
        get(target, prop) {
            const key = prop.toString();
            const pascalCase = key.toLowerCase().replace(/(?:^|_)([a-z])/g, (_, char) => char.toUpperCase());
            const upperSnakeCase = key.replace(/(?:^|([A-Z]))/g, (_, char) => (char ? '_' + char : '')).toUpperCase();
            return target[key] || target[key.toLowerCase()] || target[pascalCase] || target[upperSnakeCase] || '';
        }
    });
}

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Tab, Tabs, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import React from 'react';
import ReactJSON from 'react-json-view';
import { CreditReportType } from '../../../constants/enums';
import { IUnknownKeyAudit } from '../../../constants/types';
import Table from '../../Table/Table';

type TabPanelProps = {
    index: number;
    value: number;
    children?: React.ReactNode;
};

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function fetchProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const UnknownCreditReportKeysComponent: React.FC<IProps> = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                className="no-margin"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Table
                    tableCellStyle={{
                        border: 0,
                        fontSize: 16,
                        padding: 0
                    }}
                    bold={false}
                    tableStyle={{ marginTop: 0 }}
                    tableData={[
                        [
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Icon style={{ fontSize: 20, marginRight: 10 }}>info_outline</Icon> Unknown Report Keys
                            </div>,
                            <div style={{ float: 'right' }}>
                                <div className="numberCircle">{props.keyCount}</div>
                            </div>
                        ]
                    ]}
                />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ paddingTop: 0 }}>
                <div
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        paddingLeft: 10,
                        paddingRight: 10
                    }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="tabs">
                            {props.unknownKeys.map((item, index) => (
                                <Tab label={`${item.creditReportId} (${item.entries.length})`} {...fetchProps(index)} />
                            ))}
                        </Tabs>
                    </Box>
                    {props.unknownKeys.map((item, index) => (
                        <TabPanel value={value} index={index}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div>
                                    <p
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            marginTop: 0,
                                            marginBottom: item.reportType === CreditReportType.Company ? 10 : 5
                                        }}
                                    >
                                        {item.reportType === CreditReportType.Company
                                            ? 'Company Report'
                                            : 'Director Report:'}
                                    </p>
                                    {item.reportType === CreditReportType.Consumer && (
                                        <p style={{ fontSize: 12, marginTop: 0 }}>{item.name}</p>
                                    )}
                                </div>
                                {item.entries.map((entry, index) => (
                                    <ReactJSON
                                        src={entry}
                                        name={false}
                                        enableClipboard={false}
                                        style={{
                                            marginTop: index > 0 ? 10 : 0
                                        }}
                                    />
                                ))}
                            </div>
                        </TabPanel>
                    ))}
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default UnknownCreditReportKeysComponent;

interface IProps {
    unknownKeys: IUnknownKeyAudit[];
    keyCount: number;
}

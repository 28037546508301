import * as React from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { IAdverseInformation } from '../../../../../models/OverviewModel';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import formatCurrency from '../../../../../helpers/currencyFormatHelper';

const JudgementRow: React.FC<IProps> = (props) => {
    const { judgement } = props;

    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {dayjs(judgement.caseFilingDate).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="right">{judgement.caseNumber}</TableCell>
                <TableCell align="right">{judgement.caseReason}</TableCell>
                <TableCell align="right">{judgement.match}</TableCell>
                <TableCell align="right">{judgement.attorneyName}</TableCell>
                <TableCell align="right">{judgement.plaintiff1NameAndSurname}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: open ? 15 : 0, paddingTop: 0, borderWidth: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="additional information">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Amount
                                        </TableCell>
                                        <TableCell>{formatCurrency(judgement.amount)}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Judgement Type
                                        </TableCell>
                                        <TableCell align="right">{judgement.caseType}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Court Name
                                        </TableCell>
                                        <TableCell>{judgement.courtName}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Court Type
                                        </TableCell>
                                        <TableCell align="right">{judgement.courtType}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Registration Number
                                        </TableCell>
                                        <TableCell>{judgement.nonSAID}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default JudgementRow;

interface IProps {
    judgement: IAdverseInformation;
}

/*eslint-disable*/
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
// core components
import AdminNavbarLinks from '../Navbars/AdminNavbarLinks';

import styles from '../../assets/jss/material-dashboard-react/components/sidebarStyle';

const useStyles = makeStyles(styles);

export default function Sidebar(props: IProps) {
    const classes = useStyles();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName: string) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    const { color, logo, image, logoText, routes } = props;
    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                var activePro = ' ';
                var listItemClasses;

                listItemClasses = classNames({
                    [' ' + classes[color]]: activeRoute(prop.layout + prop.path)
                });

                const whiteFontClasses = classNames({
                    [' ' + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                });
                return (
                    <NavLink
                        to={prop.layout + prop.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            {typeof prop.icon === 'string' ? (
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                >
                                    {prop.icon}
                                </Icon>
                            ) : (
                                <prop.icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                />
                            )}
                            <ListItemText
                                primary={props.rtlActive ? prop.rtlName : prop.name}
                                className={classNames(classes.itemText, whiteFontClasses, {
                                    [classes.itemTextRTL]: props.rtlActive
                                })}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <a
                href="https://bridgement.com"
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                })}
                target="_blank"
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>
                {logoText}
            </a>
        </div>
    );
    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={props.rtlActive ? 'left' : 'right'}
                    open={props.open}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                    onClose={props.handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>
                        {props.rtlActive ? <div /> : <AdminNavbarLinks />}
                        {links}
                    </div>
                    <div className={classes.background} style={{ backgroundColor: '#fff' }} />
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor={props.rtlActive ? 'right' : 'left'}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: props.rtlActive
                        })
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    <div className={classes.background} style={{ backgroundColor: '#fff' }} />
                </Drawer>
            </Hidden>
        </div>
    );
}

interface IProps {
    rtlActive?: boolean;
    handleDrawerToggle?: any;
    bgColor?: 'purple' | 'blue' | 'green' | 'orange' | 'red';
    logo?: string;
    image?: string;
    logoText?: string;
    routes: any[];
    open?: boolean;
    color: any;
}

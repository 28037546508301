import React, { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';
import { IDirectorInformation } from '../../../models/OverviewModel';

interface IProps {
    information: IDirectorInformation[];
    directorStatusThreshold: number;
    companyThreshold: number;
    display: 'COMPANY' | 'DIRECTOR';
}

export const DirectorChanges: React.FC<IProps> = (props) => {
    const [directorStatusCount, setDirectorStatusCount] = useState<number>(0);
    const [directorCompanyStatusCount, setDirectorCompanytStatusCount] = useState<number>(0);

    useEffect(() => {
        let count = 0;
        let companyCount = 0;
        const startThreshold = dateFns.subMonths(new Date(), props.directorStatusThreshold);
        const companyStartThreshold = dateFns.subMonths(new Date(), props.directorStatusThreshold);
        for (let i = 0; i < props.information.length; i += 1) {
            const director = props.information[i];

            const startDate = dateFns.parse(director.StartDate, 'dd-MM-yyyy', new Date());
            const endDate = dateFns.parse(director.EndDate, 'dd-MM-yyyy', new Date());

            const startedAfterThreshold = dateFns.isAfter(startDate, startThreshold);

            const resignedAfterThreshold = dateFns.isAfter(endDate, startThreshold) && director.Status !== 'ACTIVE';

            if (startedAfterThreshold === true || resignedAfterThreshold === true) {
                count += 1;
            }

            for (let j = 0; j < director.LinkedCompanyInfo.length; j += 1) {
                const company = director.LinkedCompanyInfo[j];

                const companyStartDate = dateFns.parse(company.startDate, 'dd/MM/yyyy', new Date());
                const companytEndDate = dateFns.parse(company.endDate, 'dd-MM-yyyy', new Date());

                const companyStartedAfterThreshold = dateFns.isAfter(companyStartDate, companyStartThreshold);

                const companyResignedAfterThreshold =
                    dateFns.isAfter(companytEndDate, companyStartThreshold) && company.status !== 'ACTIVE';

                if (companyStartedAfterThreshold === true || companyResignedAfterThreshold === true) {
                    companyCount += 1;
                }
            }
        }

        setDirectorStatusCount((prevCount) => prevCount + count);
        setDirectorCompanytStatusCount((prevCount) => prevCount + companyCount);
    }, [props.information, props.directorStatusThreshold]);

    return (
        <>
            {props.display === 'DIRECTOR' ? (
                <div className={directorStatusCount === 0 ? '' : 'numberWarningCircle'}>
                    {directorStatusCount === 0 ? '' : directorStatusCount}
                </div>
            ) : (
                ''
            )}
            {props.display === 'COMPANY' ? (
                <div className={directorCompanyStatusCount === 0 ? '' : 'numberWarningCircle'}>
                    {directorCompanyStatusCount === 0 ? '' : directorCompanyStatusCount}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default DirectorChanges;

import React from 'react';
import useGauge from './useGauge';
import './Gauge.css';

const Gauge: React.FunctionComponent<IProps> = props => {
    const gaugeRef = useGauge(props.gaugePercentage);

    return (
        <div className="gauge-wrapper">
            <div className="gauge" ref={gaugeRef}></div>
            <span className="gauge-label">{props.label}</span>
        </div>
    );
};

export default Gauge;

interface IProps {
    gaugePercentage: number;
    label: any;
}

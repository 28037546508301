import React from 'react';
import * as dateFns from 'date-fns';

import Icon from '@material-ui/core/Icon';

import Table from '../../Table/Table';
import IconListExpansion from '../../Expansion/IconListExpansion';

import { IEnquiry } from '../../../models/OverviewModel';

const PreviousEnquiriesComponent: React.FC<IProps> = props => {
    const tableData: string[][] = [];

    for (let i = 0; i < props.enquiries.length; i += 1) {
        const enquiry = props.enquiries[i];

        tableData.push([dateFns.format(dateFns.parseISO(enquiry.enquiryDate), 'dd-MMM-yyy'), enquiry.enquiryBy]);
    }

    const companyEnquiries: JSX.Element[] = [
        <div style={{ width: '100%' }} key="abcd">
            <Table tableData={tableData}></Table>
        </div>
    ];

    return (
        <IconListExpansion
            expansionList={companyEnquiries}
            title={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Icon style={{ fontSize: 20, marginRight: 10 }}>info_outline</Icon> Previous Enquiries:{' '}
                    {tableData.length}
                </div>
            }
        ></IconListExpansion>
    );
};

export default PreviousEnquiriesComponent;

interface IProps {
    enquiries: IEnquiry[];
}

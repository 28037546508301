import { Styles } from "@material-ui/styles/withStyles";
import { Theme } from "@material-ui/core";

const cardBodyStyle: Styles<Theme, any> = {
  cardBody: {
    padding: "0.9375rem 20px",
    flex: "1 1 auto",
    WebkitBoxFlex: "initial",
    position: "relative"
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyProfile: {
    marginTop: "15px"
  }
};

export default cardBodyStyle;

import _ from 'lodash';
import {
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
    grayColor
} from '../../material-dashboard-react';
import { Styles } from '@material-ui/styles/withStyles';
import { Theme } from '@material-ui/core';

const cardIconStyle = (padding?: string): Styles<Theme, any> => theme => ({
    cardIcon: {
        '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader': {
            borderRadius: '3px',
            backgroundColor: grayColor[0],
            padding: _.isNil(padding) ? '15px' : padding,
            marginTop: '-20px',
            marginRight: '15px',
            float: 'left'
        }
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader
});

export default cardIconStyle;

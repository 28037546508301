import React, { ReactNode } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons

// core components
import styles from '../../assets/jss/material-dashboard-react/components/cardIconStyle';



export default function CardIcon(props: IProps) {
	const { className, children, color, iconPadding, ...rest } = props;
	const useStyles = makeStyles(styles(iconPadding));
    const classes = useStyles(iconPadding);

    const cardIconClasses = classNames({
        [classes.cardIcon]: true,
        [classes[color + 'CardHeader']]: color,
        [className]: className !== undefined
    });
    return (
        <div className={cardIconClasses} {...rest}>
            {children}
        </div>
    );
}

interface IProps {
    className?: any;
    color?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose';
    children?: ReactNode;
    iconPadding?: string;
}

import _ from 'lodash';

const formatCurrency = (amount: number, numberOfDigits?: number) => {
    let formattedAmount = new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'ZAR',
        maximumFractionDigits: numberOfDigits ? numberOfDigits : 0,
        minimumFractionDigits: numberOfDigits ? numberOfDigits : 0
    })
        .format(amount)
        .split('ZA')[1];

    if (amount < 0) {
        formattedAmount = `-${formattedAmount}`;
    }

    return formattedAmount;
};

export const unFormatCurrency = (amount: string): number | '' => {
    if (amount === '') {
        return '';
    }
    const removedSymbol = amount.substring(2);

    const removedDividers = removedSymbol.replace(/,/g, '');

    let parsedNumber = 0;

    try {
        parsedNumber = parseFloat(removedDividers);
        if (_.isNaN(parsedNumber)) {
            return '';
        }
    } catch (err) {
        return '';
    }

    return parsedNumber;
};

export default formatCurrency;

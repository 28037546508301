import _ from 'lodash';

export interface IDashboardResult {
    assessmentData: {
        OverviewDashboard: IOverviewDashboard;
        FraudDataDashboard: IFraudDataDashboard;
        DirectorInformation: IDirectorInformation[];
        CompanyCreditDashboard: ICompanyCreditDashboard;
        CounterPartyRiskDashboard: ICounterPartRiskDashboard;
        ApplicantDashboard: IApplicantDashboard;
    };
    juristicEntitySummary: {
        companyName: string;
        creditScore: number;
        annualRevenue?: number;
        requestedAmount?: number;
        directors: any[];
        startDate: string;
        latestCreditReport?: Date;
    };
    dynamicData: {
        accountSharingGraphUrl: string;
    };
}

export interface IMonthlyInstalment {
    provider: string;
    categoryDetails: BankStatementToCategory;
    providerAmounts: IProviderAmounts[];
    paymentFrequency: BankStatementRepaymentFrequency;
    instalmentSize: number;
    numberOfInstalments: number;
    firstInstalmentDate?: string;
    lastInstalmentDate?: string;
    openingBalance: number;
    currentBalance: number;
}

export enum BankStatementRepaymentFrequency {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Yearly = 'Yearly',
    Variable = 'Variable'
}

export interface IProviderAmounts {
    date: string;
    totalForMonth: number;
    totalIncomeForMonth: number;
    totalExpenseForMonth: number;
    statements: BankStatement[];
    incomeStatements: BankStatement[];
    expenseStatements: BankStatement[];
}

export interface BankStatement {
    Id: number;

    CreatedOn: string;

    Date: string;

    Description: string;

    Reference: string;

    Reconciled: string;

    Source: string;

    NewSource: string;

    SourceId?: string;

    SourceType: string;

    UniqueHash: string;

    PossibleDuplicate: boolean;

    Amount?: number;

    Balance: number;

    Category: string;

    CategoryType: string;

    CategorySource: string;

    JuristicEntityId: number;

    BankAccountId: number;

    SupportingDocumentId?: number;

    BankFeedJsonId?: number;

    AccountingSoftwareJsonId?: number;

    Categories?: BankStatementToCategory[];
}

export interface BankStatementToCategory {
    Id: number;

    CreatedOn: string;

    UpdatedOn?: string;

    CategoryL1: string;

    CategoryL2: string;

    CategoryL3: string;

    CategoryL1Priority: number;

    CategoryL2Priority: number;

    CategoryL3Priority: number;

    Active: boolean;

    BankStatementId: number;

    BankStatementCategorizationId: number;
}

export interface IApplicantDashboard {
    ValidIdNumber: IFraudStatus | boolean;
    ForeignId: IFraudStatus | boolean;
    IdNumberListedAsDirector: IFraudStatus | boolean;
    DirectorStatusActive: IFraudStatus | boolean;
    ApplicantDeceased: IFraudStatus | boolean;
    ApplicantSurnameMatchesCreditReport: IFraudStatus | boolean;
    ApplicantFirstInitialMatchesCreditReport: IFraudStatus | boolean;
    ApplicantFirstNameMatchesCreditReport: IFraudStatus | boolean;
    ApplicantCompanyNameMatchesCreditReport: IFraudStatus | boolean;
    ApplicantCompanyNameMatchesCreditReportLegalName: IFraudStatus | boolean;
    AccountingSoftwareConnectedForAnotherAccount: ILink;
    CompanyCIPCStatusIsGood: IStatus;
    RelatedCompanyCIPCStatusesAreallGood: IStatus;
}

export interface IFraudStatus {
    passedCheck: boolean | 'Unknown (Report not pulled)';
    confidence: 'high' | 'low';
}

export interface IStatus {
    isCorrect: boolean;
    statuses: string[];
}

export interface IOverviewDashboard {
    Website: string;
    Industry: string;
    Location: string;
    StartDate: string;
    UseOfFunds: string;
    StatedRevenue: string;
    UsedRevenue: string;
    GrossMargin: string;
    AdditionalInfo: string;
    ReferralSource: string;
    UTMSource: string;
    UTMMedium: string;
    UTMCampaign: string;
    UTMTerm: string;
    AmountRequested: string;
    RegistrationNumber: string;
    CompanyType: BusinessTypes;
    RegisteredDate: string;
    CompanyName: string;
    UpdatedOn: string;
    HowDidYouFindUs: string;
    CreditReportIds: number[];
    ServiceRepresentative: string;
}

export enum BusinessTypes {
    PrivateCompany = 1,
    PublicCompany = 2,
    NonProfit_Article_21 = 3,
    LimitedByGuarantee = 4,
    ForeignExternalCompany = 5,
    ForeignExternalCompany_UnderSection21A = 6,
    IncorporationInc = 7,
    Unlimited = 8,
    CloseCorporation_CC = 9,
    PrimaryCooperative = 10,
    SecondaryCooperative = 11,
    TertiaryCooperative = 12,
    StateOwnedCompany_SOC_Ltd = 13,
    StatutoryBody = 14,
    Unknown = 15 // catch all
}

export interface IFraudDataDashboard {
    AdditionalInfo: string;
    BankStatementSources: string[];
    AccountingPackageData: IAccountingPackageData;
    ApplicantActiveDirector: boolean;
    UpdatedOn: string;
}

export interface IAccountingPackageData {
    package: string;
    createdDate: string;
}

export interface IDirectorInformation {
    Status: string;
    EndDate: string;
    FullName: string;
    IdNumber: string;
    IsActive: boolean;
    IsApplicant: boolean;
    StartDate: string;
    CreditScore: string;
    MonthsActive: number;
    AdditionalInfo: string;
    LinkedCompanyInfo: ILinkedCompanyInfo[];
    DirectorAdverseInformation: IDirectorAdverseInformation;
    PreviousEnquiries: IEnquiry[];
    Properties: number;
    CreditFacilities: ICreditFacility[];
    BalanceExposure: number;
    MonthlyInstalment: number;
    CumulativeArrears: number;
    Age: number;
    IdentificationStatus: string;
    DeceasedStatus: string;
    RiskEvaluation: IDirectorRiskEvaluation;
    DirectorCreditHistory: ICreditHistory[];
    DeceasedAccordingToLinkedCompany: boolean;
    PublicDefaults: IPublicDefault[];
    IsSecretary?: boolean;
    IsDirector?: boolean;
    IsFounder?: boolean;
}

export interface ICreditFacility {
    accountType: string;
    creditProvider: string;
    currentBalance: number;
    instalmentAmount: number;
    lastPayment: string;
    openingBalance: number;
    paymentHistory: string;
    soldToThirdParty: boolean;
    status: string;
    thirdPartyName: string;
    type: 'CPA' | 'NLR';
}

export interface ICountArray<T> {
    Count: number;
    Details: T[];
}

export interface INewCountArray<T> {
    Count: number;
    Details: T[];
}

export type LegacyCountArray<T> = ICountArray<T> | T[];

export interface IDirectorAdverseInformation {
    fraudAlerts: LegacyCountArray<IFraudAlert>;
    adverse: LegacyCountArray<IAdverse>;
    debtRestructuring: LegacyCountArray<IDebtRestructuring>;
    notices: LegacyCountArray<INotice>;
    judgements: LegacyCountArray<IJudgementInformation>;
    debtCollections: LegacyCountArray<IDebtCollection>;
    sasfaIndicators?: number;
}

export const isNewCountSystem = <T>(adverseInformation: ICountArray<T> | T[]): adverseInformation is ICountArray<T> => {
    if (!_.isNil((adverseInformation as ICountArray<T>)?.Details)) {
        return true;
    }

    return false;
};

export const ConvertToNewSystem = <T>(adverseInformation: ICountArray<T> | T[]): INewCountArray<T> => {
    if (isNewCountSystem(adverseInformation)) {
        return {
            Details: adverseInformation.Details,
            Count: adverseInformation.Count ?? adverseInformation.Details.length
        };
    }

    return {
        Count: adverseInformation.length,
        Details: adverseInformation
    };
};

export interface IFraudAlert {
    referenceNumber: string;
    category: string;
    subcategory: string;
    subject: string;
    identificationNumber: string;
    victim: boolean;
    incidentDate: string;
    details: string;
}

export interface IAdverse {
    terms: string;
    openBalance: number;
    openDate: string;
    accountNumber: string;
    branchCode: string;
    currentBalance: number;
    reason: string;
    dateCreated: string;
    adverseDate: string;
    arrearsPeriod: number;
    overDueAmount: number;
    paymentHistory: string;
    subscriberName: string;
    statusCodeDescription: string;
    accountType: string;
    adverseDescription: string;
    installmentAmount: number;
    ownershipType: string;
    repaymentFrequency: string;
}

export interface IDebtRestructuring {
    registrationNumber: string;
    status: string;
    telephoneNumber: string;
    statusDate: string;
    statusDescription: string;
    contactPerson: string;
    applicationDate: string;
}

export interface INotice {
    type: string;
    amount: string;
    reason: string;
    caseNo: string;
    attorney: string;
    plaintiff: string;
    courtName: string;
    courtType: string;
    branchName: string;
    dateIssued: string; // yyyy-mm-dd;
    contactPerson: string;
    telephoneNumber: string;
}

export interface IJudgementInformation {
    match: string;
    amount: number;
    caseType: string;
    nonSAID: string;
    courtName: string;
    courtType: string;
    caseNumber: string;
    caseReason: string;
    attorneyName: string;
    caseFilingDate: string;
    plaintiff1NameAndSurname: string;
    plaintiff2NameAndSurname?: string;
    plaintiff3NameAndSurname?: string;
    plaintiff4NameAndSurname?: string;
}

export interface IDebtCollection {
    accountNumber: string;
    industryNumber: string;
    employer: string;
    company: string;
    amount: string;
    contact: string;
    status: string;
    statusDate: string;
}

export interface ILinkedCompanyInfo {
    name: string;
    status: string;
    endDate: string;
    startDate: string;
}

export interface ICompanyCreditDashboard {
    Enquiries: IEnquiry[];
    CreditScore: number;
    CompanyStatus: string;
    VatRegistered: boolean;
    AdditionalInfo: string;
    NumberOfDirectors: number;
    AdverseInformation: IAdverseInformation[];
    UpdatedOn: string;
    RiskEvaluation: ICompanyRiskEvaluation;
    CompanyCreditHistory: ICreditHistory[];
    SasfaInfo: ISasfaInformation[];
}

export interface IAdverseInformation {
    match: string;
    amount: number;
    caseType: string;
    nonSAID: string;
    courtName: string;
    courtType: string;
    caseNumber: string;
    caseReason: string;
    attorneyName: string;
    caseFilingDate: string;
    plaintiff1NameAndSurname: string;
    plaintiff2NameAndSurname?: string;
    plaintiff3NameAndSurname?: string;
    plaintiff4NameAndSurname?: string;
}

export interface IEnquiry {
    enquiryBy: string;
    enquiryDate: string;
}

export interface ILink {
    isLinked: boolean;
    juristicEntities: ILinkage[];
}

export interface ILinkage {
    directorEmail?: string;
    directorId?: string;
    directorName?: string;
    juristicEntityId: number;
    juristicEntityName: string;
}

export interface ICounterPartRiskDashboard {
    AdditionalInfo: string;
    ApplicantEmailExistsOnOtherUser: ILink;
    ApplicantIdNumberExistsOnOtherUser: ILink;
    ApplicantEmailDomainExistsOnOtherUser: ILink;
    ApplicantMobileNumberExistsOnOtherUser: ILink;
    NonApplicantDirectorIdExistsOnOtherUser: ILink;
    DirectorRelatedCompanyMatchesExistingCompany: ILink;
    ApplicantIdNumberExistsOnOtherCompanyCreditReport: ILink;
    NonApplicantDirectorContactNumberExistsOnOtherUser: ILink;
    ApplicantMobileNumberExistsOnOtherCompanyCreditReport: ILink;
    ApplicantCompanyRegistrationNumberExistsOnOtherCompany: ILink;
    NonApplicantDirectorIdExistsOnOtherCompanyCreditReport: ILink;
    ApplicantRelatedCompanyIsARelatedCompanyOnOtherCompanyDirector: ILink;
    NonApplicantDirectorContactNumberExistsOnOtherCompanyCreditReport: ILink;
    DirectorRelatedCompanyMatchesAnotherCompanyDirectorRelatedCompanies: ILink;
    DeviceFingerprintExistsOnOtherUser: ILink;
    UpdatedOn: string;
}

export interface ICompanyRiskEvaluation {
    DECLINE_R_1_DESC: string;
    DECLINE_R_2_DESC: string;
    DECLINE_R_3_DESC: string;
    DECLINE_R_4_DESC: string;
    DECLINE_R_5_DESC: string;
}

export interface IDirectorRiskEvaluation {
    DECLINE_R_1: string;
    DECLINE_R_2: string;
    DECLINE_R_3: string;
    DECLINE_R_4: string;
    DECLINE_R_5: string;
}

export interface ICreditHistory {
    score: string;
    version: string;
    date: Date;
}

export interface ISasfaInformation {
    SUBSCRIBER_NAME: string;
    CUSTOMER_NUMBER: string;
    COMPANY_NAME: string;
    TRADING_NAME: string;
    ENTITY_TYPE: string;
    PRODUCT: string;
    REPAYMENT_METHOD: string;
    PAYMENT_FREQUENCY: string;
    DATE_DISBURSED: string;
    WRITE_OFF_DATE: string;
    WRITE_OFF_AMOUNT: string;
    BILLING_STREET: string;
    BILLING_CITY: string;
    BILLING_PROVINCE: string;
    BILLING_COUNTRY: string;
    BILLING_POSTAL_CODE: string;
    ACCOUNT_STATUS: string;
}

export interface IPublicDefault {
    BRANCH_NAME: string;
    DEFAULT_DATE: string;
    DEFAULT_AMOUNT: string;
    FK_SUBSCR_CODE: string;
    LEGAL_ACTION_DESC: string;
    ACCOUNT_REF_NUMBER: string;
}

import { ICreditHistory, IDirectorInformation } from '../../../models/OverviewModel';

import CreditScoreTimeline from '../../Graph/CreditScoreTimeline/CreditScoreTimeline';
import Icon from '@material-ui/core/Icon';
import IconListExpansion from '../../Expansion/IconListExpansion';
import React from 'react';

const CreditHistoryAnnotationComponent: React.FC<IProps> = (props) => {
    return (
        <IconListExpansion
            expansionList={[
                <div
                    style={{
                        paddingBottom: 10,
                        width: '100%'
                    }}
                >
                    <CreditScoreTimeline
                        key="credit_score_timeline"
                        directors={props.directorInformation}
                        companyCreditHistory={props.companyCreditHistory}
                        companyName={props.companyName}
                    />
                </div>
            ]}
            title={
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingRight: 5
                        }}
                    >
                        <Icon style={{ fontSize: 20, marginRight: 10 }}>info_outline</Icon> Company / Director Credit
                        History
                    </div>
                </>
            }
        ></IconListExpansion>
    );
};

export default CreditHistoryAnnotationComponent;

interface IProps {
    directorInformation: IDirectorInformation[];
    companyCreditHistory: ICreditHistory[];
    companyName: string;
}

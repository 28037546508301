import { Button, Link } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { isEqual, isNil } from 'lodash';
import React from 'react';
import { useSetRecoilState } from 'recoil';
// core components
import styles from '../../assets/jss/material-dashboard-react/components/tableStyle';
import { cipcStatusModalAtom } from '../../atoms';

export default function CustomTable(props: IProps) {
    const setShowCIPCStatusModal = useSetRecoilState(cipcStatusModalAtom);

    const useStyles = makeStyles(styles(props.tableCellStyle, props.tableStyle));

    const classes = useStyles() as any;

    const { nested, tableData, tableHead, tableHeaderColor, tableTitle } = props;

    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => {
                                return (
                                    <TableCell className={classes.tableCell + ' ' + classes.tableHeadCell} key={key}>
                                        {prop}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((prop, key) => {
                        let flagCell = false;

                        let soldToThirdParty = {
                            sold: false,
                            to: ''
                        };

                        if (props.metaData && props.metaData.length > 0) {
                            for (let i = 0; i < props.metaData.length; i++) {
                                const flagableEntity = props.metaData[i].entry;

                                if (isEqual(prop, flagableEntity)) {
                                    flagCell = true;

                                    if (
                                        !isNil(props.metaData[i].soldToThirdParty) &&
                                        props.metaData[i].soldToThirdParty.sold
                                    ) {
                                        soldToThirdParty = { sold: true, to: props.metaData[i].soldToThirdParty.to };
                                    }

                                    break;
                                }
                            }
                        }

                        let isRelatedCIPC = false;

                        if (prop[0] === 'Related company CIPC statuses are all good') {
                            isRelatedCIPC = true;
                        }

                        return (
                            <TableRow
                                key={key}
                                className={classes.tableBodyRow}
                                style={{
                                    backgroundColor: soldToThirdParty.sold ? red[700] : flagCell ? red[500] : 'white'
                                }}
                            >
                                {prop.map((prop, key) => {
                                    const isNestedCounterPartyRiskCheckOrganisationMatch =
                                        key === 0 &&
                                        nested &&
                                        !!tableTitle &&
                                        tableTitle === 'Passed Counter-Party Risk Checks';

                                    let juristicEntityId = '';

                                    if (isNestedCounterPartyRiskCheckOrganisationMatch) {
                                        const index = prop.lastIndexOf('(');

                                        juristicEntityId = prop.slice(index + 1, prop.length - 1);
                                    }

                                    return (
                                        <TableCell className={classes.tableCell} key={key}>
                                            {isNestedCounterPartyRiskCheckOrganisationMatch ? (
                                                <Link
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`https://app.forestadmin.com/Bridgement.1/Production/Credit/data/DbJuristicEntity/index/record/DbJuristicEntity/${juristicEntityId}`}
                                                >
                                                    {prop}
                                                </Link>
                                            ) : key === 0 && props.bold !== false ? (
                                                <strong style={{ color: flagCell ? 'white' : 'black', margin: 0 }}>
                                                    {prop}
                                                </strong>
                                            ) : isRelatedCIPC && key === 2 ? (
                                                <div style={{ flexDirection: 'column' }}>
                                                    {!isNil(prop[2]) && (
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() => setShowCIPCStatusModal(true)}
                                                        >
                                                            View
                                                        </Button>
                                                    )}
                                                </div>
                                            ) : (
                                                <div style={{ flexDirection: 'column', maxWidth: 400 }}>
                                                    <p style={{ color: flagCell ? 'white' : 'black', margin: 0 }}>
                                                        {prop}
                                                    </p>
                                                    {soldToThirdParty.sold && key === 1 && (
                                                        <div style={{ flexDirection: 'column', marginTop: 5 }}>
                                                            <p
                                                                style={{
                                                                    color: 'white',
                                                                    margin: 0,
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >
                                                                Sold to:
                                                            </p>
                                                            <p style={{ color: 'white', margin: 0 }}>
                                                                {soldToThirdParty.to}
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
}

interface IProps {
    tableData: any[][];
    tableHeaderColor?: 'warning' | 'primary' | 'danger' | 'success' | 'info' | 'rose' | 'gray';
    bold?: boolean;
    metaData?: any[];
    nested?: boolean;
    tableCellStyle?: React.CSSProperties;
    tableHead?: string[];
    tableStyle?: React.CSSProperties;
    tableTitle?: string;
}

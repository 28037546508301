import './ExpansionTableRow.css';

import * as React from 'react';

import { IAdverseInformation, ISasfaInformation } from '../../../models/OverviewModel';
import { JudgementRow, SasfaRow } from './CollapsibleRow';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomTable from '../../Table/Table';
import IconListExpansion from '../../Expansion/IconListExpansion';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { green } from '@material-ui/core/colors';

const CompanyJudgementsComponent: React.FC<IProps> = (props) => {
    const judgements = props.adverseInformation;

    const sasfaEntries = props.sasfaInformation;

    return (
        <IconListExpansion
            expansionList={[
                <div
                    style={{
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: judgements.length === 0 && sasfaEntries.length === 0 ? 0 : 10,
                        width: '100%'
                    }}
                >
                    {judgements.length > 0 || sasfaEntries.length > 0 ? (
                        <React.Fragment>
                            {judgements.length > 0 && (
                                <>
                                    <Typography variant="subtitle1" gutterBottom component="div" fontWeight="bold">
                                        Judgements
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: '#F0F0F0' }}>
                                                    <TableCell />
                                                    <TableCell>Date Issued</TableCell>
                                                    <TableCell align="right">Case Number</TableCell>
                                                    <TableCell align="right">Reason</TableCell>
                                                    <TableCell align="right">Match</TableCell>
                                                    <TableCell align="right">Attorney Name</TableCell>
                                                    <TableCell align="right">Plaintiff</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {judgements.map((judgement, index) => (
                                                    <JudgementRow judgement={judgement} key={index} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}
                            {sasfaEntries.length > 0 && (
                                <div
                                    style={{
                                        paddingTop: judgements.length > 0 ? 20 : 0
                                    }}
                                >
                                    <Typography variant="subtitle1" gutterBottom component="div" fontWeight="bold">
                                        SASFA
                                    </Typography>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow sx={{ backgroundColor: '#F0F0F0' }}>
                                                    <TableCell />
                                                    <TableCell>Customer Number</TableCell>
                                                    <TableCell align="right">Trading Name</TableCell>
                                                    <TableCell align="right">Product</TableCell>
                                                    <TableCell align="right">Date Disbursed</TableCell>
                                                    <TableCell align="right">Account Status</TableCell>
                                                    <TableCell align="right">Subscriber Name</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {sasfaEntries.map((entry, index) => (
                                                    <SasfaRow entry={entry} key={index} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}
                        </React.Fragment>
                    ) : (
                        <Typography variant="body1" gutterBottom>
                            No items to display
                        </Typography>
                    )}
                </div>
            ]}
            title={
                <CustomTable
                    tableCellStyle={{
                        border: 0,
                        fontSize: 16
                    }}
                    bold={false}
                    tableStyle={{ marginTop: 0 }}
                    tableData={[
                        [
                            'Company Judgements + SASFA',
                            judgements.length === 0 && sasfaEntries.length === 0 ? (
                                <div className="numberCircleSize" style={{ float: 'right' }}>
                                    <CheckCircleIcon style={{ color: green[500] }} />
                                </div>
                            ) : (
                                <div style={{ float: 'right' }}>
                                    <div className="numberCircle">{judgements.length + sasfaEntries.length}</div>
                                </div>
                            )
                        ]
                    ]}
                ></CustomTable>
            }
        />
    );
};

export default CompanyJudgementsComponent;

interface IProps {
    adverseInformation: IAdverseInformation[];
    sasfaInformation: ISasfaInformation[];
}

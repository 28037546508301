import { atom } from 'recoil';
import { IDateOption } from '../constants/types';

export const assessmentDateAtom = atom<IDateOption | null>({
    key: 'assessmentDate',
    default: null
});

export const cipcStatusModalAtom = atom<boolean>({
    key: 'cipcStatusModal',
    default: false
});

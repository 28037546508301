/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from '@material-ui/icons/Dashboard';
import Overview from './views/Overview/Overview';

const dashboardRoutes = [
    {
        path: '/overview',
        name: 'Overview',
        rtlName: 'لوحة القيادة',
        icon: Dashboard,
        component: Overview,
        layout: '/admin'
    }
];

export default dashboardRoutes;

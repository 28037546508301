import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import * as dateFns from 'date-fns';
import Chart from 'chart.js';
import { IDirectorInformation } from '../../../models/OverviewModel';

const useTimeline = (
    graphData: Chart.ChartDataSets[],
    graphLabels: string[],
    directors: IDirectorInformation[],
    minimumDate?: Date
) => {
    const [currentChart, setCurrentChart] = useState<Chart | null>(null);
    const ref = useRef<HTMLCanvasElement>(null);

    const changeData = (chart: Chart, labels: string[], datasets: Chart.ChartDataSets[]) => {
        chart.data.labels = labels;
        chart.data.datasets = datasets;
        chart.update();
    };

    useEffect(() => {
        const currentRef = ref.current;

        if (!_.isNil(currentRef)) {
            const ctx = currentRef.getContext('2d');
            if (!_.isNil(ctx) && !_.isNil(graphData) && graphData.length > 0) {
                const chartData = _.cloneDeep(graphData);
                if (_.isNil(currentChart)) {
                    const chart = new Chart(ctx, {
                        type: 'horizontalBar',
                        data: {
                            labels: graphLabels,
                            datasets: chartData
                        },
                        options: {
                            title: {
                                display: true,
                                text: 'Director-Company Timeline'
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        if (!_.isNil(tooltipItem.xLabel) && !_.isNil(tooltipItem.yLabel)) {
                                            const label = tooltipItem.xLabel;

                                            const name = tooltipItem.yLabel;

                                            const director = _.find(directors, (o) => o.FullName === name);

                                            const creditScore = director?.CreditScore;

                                            if (!_.isNaN(label) && !_.isNil(creditScore)) {
                                                const parsedLabel = JSON.parse(`${label}`);

                                                const start = dateFns.format(new Date(parsedLabel[0]), 'dd-MM-yyyy');
                                                const end = dateFns.format(new Date(parsedLabel[1]), 'dd-MM-yyyy');

                                                return `Credit Score: ${creditScore}, ${start} to ${end}`;
                                            }

                                            return '';
                                        }

                                        return '';
                                    }
                                }
                            },
                            responsive: true,
                            scales: {
                                xAxes: [
                                    {
                                        stacked: true,
                                        ticks: {
                                            callback: function (value, index, values) {
                                                if (!_.isNil(value) && dateFns.isValid(value) && !isString(value)) {
                                                    return dateFns.format(value, 'dd-MMM-yyyy');
                                                } else if (
                                                    !_.isNil(value) &&
                                                    dateFns.isValid(value) &&
                                                    isString(value)
                                                ) {
                                                    return dateFns.format(dateFns.parseISO(value), 'dd-MMM-yyyy');
                                                }

                                                return '';
                                            },
                                            min: minimumDate,

                                            minRotation: 45
                                        }
                                    }
                                ],
                                yAxes: [
                                    {
                                        stacked: true
                                    }
                                ]
                            }
                        }
                    });
                    setCurrentChart(chart);
                } else {
                    changeData(currentChart, graphLabels, graphData);
                }
            }
        }
    }, [graphData, graphLabels, minimumDate, currentChart, directors]);

    return ref;
};

const isString = (value: number | Date | string): value is string => {
    return _.isString(value);
}



export default useTimeline;

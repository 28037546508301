import * as React from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { ISasfaInformation } from '../../../../../models/OverviewModel';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { createCaseInsensitiveProxy } from '../../../../../helpers/objectHelper';

function formatDate(date: string) {
    return dayjs(new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))).format('DD/MM/YYYY');
}

const SasfaRow: React.FC<IProps> = (props) => {
    const { entry: entryProp } = props;
    const entry = createCaseInsensitiveProxy(entryProp);

    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {entry.CUSTOMER_NUMBER}
                </TableCell>
                <TableCell align="right">{entry.TRADING_NAME}</TableCell>
                <TableCell align="right">{entry.PRODUCT}</TableCell>
                <TableCell align="right">{formatDate(entry.DATE_DISBURSED)}</TableCell>
                <TableCell align="right">{entry.ACCOUNT_STATUS}</TableCell>
                <TableCell align="right">{entry.SUBSCRIBER_NAME}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: open ? 15 : 0, paddingTop: 0, borderWidth: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="additional information">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Repayment Method
                                        </TableCell>
                                        <TableCell>{entry.REPAYMENT_METHOD}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Entity Type
                                        </TableCell>
                                        <TableCell align="right">{entry.ENTITY_TYPE}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Write-off Date
                                        </TableCell>
                                        <TableCell>{formatDate(entry.WRITE_OFF_DATE)}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Payment Frequency
                                        </TableCell>
                                        <TableCell align="right">{entry.PAYMENT_FREQUENCY}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Billing Street
                                        </TableCell>
                                        <TableCell>{entry.BILLING_STREET}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Write-off Amount
                                        </TableCell>
                                        <TableCell align="right">{entry.WRITE_OFF_AMOUNT}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Billing Province
                                        </TableCell>
                                        <TableCell>{entry.BILLING_PROVINCE}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Billing City
                                        </TableCell>
                                        <TableCell align="right">{entry.BILLING_CITY}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
                                            Billing Postal Code
                                        </TableCell>
                                        <TableCell>{entry.BILLING_POSTAL_CODE}</TableCell>
                                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                            Billing Country
                                        </TableCell>
                                        <TableCell align="right">{entry.BILLING_COUNTRY}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default SasfaRow;

interface IProps {
    entry: ISasfaInformation;
}

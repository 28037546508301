import {
    Box,
    Divider,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as dateFns from 'date-fns';
import * as _ from 'lodash';
import React from 'react';
import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle';
import formatCurrency from '../../../helpers/currencyFormatHelper';
import { ConvertToNewSystem, IDirectorInformation } from '../../../models/OverviewModel';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import Table from '../../Table/Table';
import PreviousEnquiriesComponent from '../PreviousEnquiries/PreviousEnquiriesComponent';

const getBackgroundColor = (numberOfFlags: number | undefined): string => {
    if (_.isNumber(numberOfFlags) && numberOfFlags > 0) {
        return red[500];
    }

    return 'white';
};

const useStyles = makeStyles(styles);

const DirectorDetailsComponent: React.FC<IProps> = (props) => {
    const classes = useStyles();

    const {
        DeceasedStatus,
        IdentificationStatus,
        DirectorAdverseInformation,
        LinkedCompanyInfo,
        Properties,
        PublicDefaults
    } = props.directorInformation;
    console.log(DirectorAdverseInformation);

    let fraudAlerts = ConvertToNewSystem(DirectorAdverseInformation.fraudAlerts);
    let judgements = ConvertToNewSystem(DirectorAdverseInformation.judgements);
    let debtRestructuring = ConvertToNewSystem(DirectorAdverseInformation.debtRestructuring);
    let adverse = ConvertToNewSystem(DirectorAdverseInformation.adverse);
    let notices = ConvertToNewSystem(DirectorAdverseInformation.notices);
    let debtCollections = ConvertToNewSystem(DirectorAdverseInformation.debtCollections);

    const sasfaIndicators = DirectorAdverseInformation.sasfaIndicators ?? 0;

    const creditFacilities = _.orderBy(props.directorInformation.CreditFacilities, (o) => o.currentBalance, 'desc');

    const hasCreditFacilities = creditFacilities.length > 0;

    const isDeceased =
        props.directorInformation.DeceasedStatus === 'Deceased' ||
        props.directorInformation.DeceasedAccordingToLinkedCompany;

    let showReasonsForScore = false;

    if (props?.directorInformation?.RiskEvaluation) {
        showReasonsForScore =
            Object.values(props.directorInformation.RiskEvaluation).filter((reason) => !!reason).length > 0;
    }

    const showDetailsHeading =
        hasCreditFacilities ||
        showReasonsForScore ||
        (fraudAlerts && fraudAlerts.Details.length > 0) ||
        (adverse && adverse.Details.length > 0) ||
        (debtRestructuring && debtRestructuring.Details.length > 0) ||
        (notices && notices.Details.length > 0) ||
        (judgements && judgements.Details.length > 0);

    const accountsSoldToThirdPartyCount =
        creditFacilities && creditFacilities.length > 0
            ? creditFacilities.filter((account) => account.soldToThirdParty).length
            : 0;

    const flaggedFacilityCount =
        props.flaggedFacilities && props.flaggedFacilities.length > 0
            ? props.flaggedFacilities.length - accountsSoldToThirdPartyCount
            : 0;

    const hasPublicDefaults = PublicDefaults ? PublicDefaults.length > 0 : false;

    const hasFraudAlertDetails = fraudAlerts.Details.filter((alert) => alert.details !== '').length > 0;

    return (
        <div style={{ width: '100%', marginTop: 10 }}>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Grid container style={{ textAlign: 'left' }}>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            SAFPS Alerts
                        </Grid>
                        {fraudAlerts && (
                            <Grid
                                item
                                xs={6}
                                style={{
                                    paddingLeft: 10,
                                    backgroundColor: getBackgroundColor(fraudAlerts.Count)
                                }}
                            >
                                <p
                                    style={{
                                        color: fraudAlerts && fraudAlerts.Count > 0 ? 'white' : 'black',
                                        margin: 0
                                    }}
                                >
                                    {fraudAlerts.Count}
                                </p>
                            </Grid>
                        )}
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            SASFA Indicators
                        </Grid>
                        {
                            <Grid
                                item
                                xs={6}
                                style={{
                                    paddingLeft: 10,
                                    backgroundColor: getBackgroundColor(sasfaIndicators)
                                }}
                            >
                                <p
                                    style={{
                                        color: sasfaIndicators && sasfaIndicators > 0 ? 'white' : 'black',
                                        margin: 0
                                    }}
                                >
                                    {sasfaIndicators}
                                </p>
                            </Grid>
                        }
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Judgements
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(judgements.Count)
                            }}
                        >
                            <p style={{ color: judgements && judgements.Count > 0 ? 'white' : 'black', margin: 0 }}>
                                {judgements.Count}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Debt Restructuring
                        </Grid>
                        {debtRestructuring && (
                            <Grid
                                item
                                xs={6}
                                style={{
                                    paddingLeft: 10,
                                    backgroundColor: getBackgroundColor(debtRestructuring.Count)
                                }}
                            >
                                <p
                                    style={{
                                        color: debtRestructuring && debtRestructuring.Count > 0 ? 'white' : 'black',
                                        margin: 0
                                    }}
                                >
                                    {debtRestructuring.Count}
                                </p>
                            </Grid>
                        )}
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Written Off AcCounts
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(
                                    _.filter(adverse.Details, (o) => o.adverseDescription === 'WRITTEN OFF').length
                                )
                            }}
                        >
                            <p
                                style={{
                                    color:
                                        _.filter(adverse.Details, (o) => o.adverseDescription === 'WRITTEN OFF')
                                            .length > 0
                                            ? 'white'
                                            : 'black',
                                    margin: 0
                                }}
                            >
                                {_.filter(adverse.Details, (o) => o.adverseDescription === 'WRITTEN OFF').length}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Adverse Accounts
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(adverse.Count)
                            }}
                        >
                            <p style={{ color: adverse && adverse.Count > 0 ? 'white' : 'black', margin: 0 }}>
                                {adverse.Count}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Default Accounts
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(PublicDefaults ? PublicDefaults.length : 0)
                            }}
                        >
                            <p
                                style={{
                                    color: PublicDefaults && PublicDefaults.length > 0 ? 'white' : 'black',
                                    margin: 0
                                }}
                            >
                                {PublicDefaults ? PublicDefaults.length : 0}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Notices
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(_.isNil(notices) ? 0 : notices.Count)
                            }}
                        >
                            <p
                                style={{
                                    color: _.isNil(notices) || notices.Count === 0 ? 'black' : 'white',
                                    margin: 0
                                }}
                            >
                                {notices ? notices.Count : 0}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Debt Sold to 3rd-Party
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(accountsSoldToThirdPartyCount)
                            }}
                        >
                            <p
                                style={{
                                    color: accountsSoldToThirdPartyCount === 0 ? 'black' : 'white',
                                    margin: 0
                                }}
                            >
                                {accountsSoldToThirdPartyCount}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Flagged Facilities
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(flaggedFacilityCount)
                            }}
                        >
                            <p
                                style={{
                                    color: flaggedFacilityCount === 0 ? 'black' : 'white',
                                    margin: 0
                                }}
                            >
                                {flaggedFacilityCount}
                            </p>
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Debt Collections
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: getBackgroundColor(
                                    _.isNil(debtCollections) || debtCollections.Count === 0 ? 0 : debtCollections.Count
                                )
                            }}
                        >
                            <p
                                style={{
                                    color: _.isNil(debtCollections) || debtCollections.Count === 0 ? 'black' : 'white',
                                    margin: 0
                                }}
                            >
                                {debtCollections ? debtCollections.Count : 0}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid style={{ textAlign: 'left' }} container>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Identification Status
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: IdentificationStatus === 'Deceased' ? red[500] : '#f5f5f5'
                            }}
                        >
                            {IdentificationStatus}
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Deceased Status
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                paddingLeft: 10,
                                backgroundColor: isDeceased ? red[500] : '#f5f5f5'
                            }}
                        >
                            {isDeceased ? 'Deceased' : `${DeceasedStatus}`}
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Active Companies
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 10 }}>
                            {LinkedCompanyInfo.length}
                        </Grid>
                        <Grid item xs={6} style={{ backgroundColor: '#f5f5f5', textAlign: 'right', paddingRight: 10 }}>
                            Properties Owned
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 10 }}>
                            {Properties}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Divider variant="middle" />
                </Grid>

                {showDetailsHeading && (
                    <Grid item xs={12} style={{ marginTop: 10, marginBottom: 5 }}>
                        <Typography variant="subtitle1" component="div">
                            Details
                        </Typography>
                    </Grid>
                )}

                {fraudAlerts && fraudAlerts.Details.length > 0 ? (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>Fraud Alerts</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHead={
                                        hasFraudAlertDetails
                                            ? [
                                                  'Reference No.',
                                                  'ID No.',
                                                  'Incident Date',
                                                  'Subject',
                                                  'Victim',
                                                  'Subcategory',
                                                  'Details'
                                              ]
                                            : [
                                                  'Reference No.',
                                                  'ID No.',
                                                  'Incident Date',
                                                  'Subject',
                                                  'Victim',
                                                  'Subcategory'
                                              ]
                                    }
                                    tableData={fraudAlerts.Details.map((alert) => {
                                        if (hasFraudAlertDetails) {
                                            return [
                                                alert.referenceNumber,
                                                alert.identificationNumber,
                                                alert.incidentDate,
                                                alert.subject,
                                                alert.victim === true ? 'YES' : 'NO',
                                                alert.subcategory,
                                                alert.details
                                            ];
                                        } else {
                                            return [
                                                alert.referenceNumber,
                                                alert.identificationNumber,
                                                alert.incidentDate,
                                                alert.subject,
                                                alert.victim === true ? 'YES' : 'NO',
                                                alert.subcategory
                                            ];
                                        }
                                    })}
                                ></Table>
                            </CardBody>
                        </Card>
                    </Grid>
                ) : (
                    ''
                )}

                {adverse && adverse.Details.length > 0 ? (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>Adverse/Written Off Accounts</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHead={[
                                        'Account Number',
                                        'Account Type',
                                        'Adverse Date',
                                        'Description',
                                        'Current Balance',
                                        'Opening Balance',
                                        'Overdue Amount',
                                        'Payment History',
                                        'Reason',
                                        'Repayment Frequency',
                                        'Name'
                                    ]}
                                    tableData={adverse.Details.map((alert) => {
                                        return [
                                            alert.accountNumber,
                                            alert.accountType,
                                            dateFns.format(dateFns.parseISO(alert.adverseDate), 'dd-MM-yyyy'),
                                            alert.adverseDescription,
                                            formatCurrency(alert.currentBalance),
                                            formatCurrency(alert.openBalance),
                                            formatCurrency(alert.overDueAmount),
                                            alert.paymentHistory.split('').join(' '),
                                            alert.reason,
                                            alert.repaymentFrequency,
                                            alert.subscriberName
                                        ];
                                    })}
                                />
                            </CardBody>
                        </Card>
                    </Grid>
                ) : (
                    ''
                )}

                {debtRestructuring && debtRestructuring.Details.length > 0 ? (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>Debt Restructuring</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHead={[
                                        'Registration No.',
                                        'Contact',
                                        'Contact No.',
                                        'Application Date',
                                        'Status',
                                        'Status Date'
                                    ]}
                                    tableData={debtRestructuring.Details.map((alert) => {
                                        return [
                                            alert.registrationNumber,
                                            alert.contactPerson,
                                            alert.telephoneNumber,
                                            alert.applicationDate,
                                            alert.statusDescription,
                                            alert.statusDate
                                        ];
                                    })}
                                ></Table>
                            </CardBody>
                        </Card>
                    </Grid>
                ) : (
                    ''
                )}

                {notices && notices.Details.length > 0 ? (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>Notices</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHead={[
                                        'Type',
                                        'Amount',
                                        'Reason',
                                        'Case No',
                                        'Attorney',
                                        'Plaintiff',
                                        'Court Name',
                                        'Court Type',
                                        'Branch Name',
                                        'Date Issued',
                                        'Contact Person',
                                        'Telephone Number'
                                    ]}
                                    tableData={notices.Details.map((alert) => {
                                        return [
                                            alert.type,
                                            alert.amount,
                                            alert.reason,
                                            alert.caseNo,
                                            alert.attorney,
                                            alert.plaintiff,
                                            alert.courtName,
                                            alert.courtType,
                                            alert.branchName,
                                            alert.dateIssued,
                                            alert.contactPerson,
                                            alert.telephoneNumber
                                        ];
                                    })}
                                ></Table>
                            </CardBody>
                        </Card>
                    </Grid>
                ) : (
                    ''
                )}

                {judgements && judgements.Details.length > 0 ? (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>Judgements</h4>
                            </CardHeader>
                            <CardBody>
                                {judgements.Details.map((alert, key) => {
                                    return (
                                        <Grid container key={key}>
                                            <Grid item xs={12} md={2}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        Case No.
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        <Divider variant="fullWidth" />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        {alert.caseNumber}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        Amount
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        <Divider variant="fullWidth" />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        {formatCurrency(alert.amount)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        Case Type
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        <Divider variant="fullWidth" />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        {alert.caseType}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        Reason
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        <Divider variant="fullWidth" />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        {alert.caseReason}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        Date Issued
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        <Divider variant="fullWidth" />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingBottom: 10 }}>
                                                        {dateFns.format(
                                                            dateFns.parseISO(alert.caseFilingDate),
                                                            'dd-MM-yyyy'
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}
                                            >
                                                <Grid container style={{ textAlign: 'center' }}>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Date Issued
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textTransform: 'capitalize' }}>
                                                        {dateFns.format(
                                                            dateFns.parseISO(alert.caseFilingDate),
                                                            'dd-MM-yyyy'
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Case No.
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textTransform: 'capitalize' }}>
                                                        {alert.caseNumber.toLowerCase()}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Court Type
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textTransform: 'capitalize' }}>
                                                        {alert.courtType.toLowerCase()}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Court Name
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textTransform: 'capitalize' }}>
                                                        {alert.courtName.toLowerCase()}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Amount
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {formatCurrency(alert.amount)}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Judgement Type
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {alert.caseType}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}
                                            >
                                                <Grid style={{ textAlign: 'center' }} container>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Attorney Name
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textTransform: 'capitalize' }}>
                                                        {alert.attorneyName.toLowerCase()}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Reason
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {alert.caseReason}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Plaintiff
                                                    </Grid>
                                                    <Grid item xs={6} style={{ textTransform: 'capitalize' }}>
                                                        {alert.plaintiff1NameAndSurname.toLowerCase()}
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Branch Name
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        &nbsp;
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Contact Person
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        &nbsp;
                                                    </Grid>
                                                    <Grid item xs={6} style={{ backgroundColor: '#f5f5f5' }}>
                                                        Contact No.
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        &nbsp;
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </CardBody>
                        </Card>
                    </Grid>
                ) : (
                    ''
                )}

                {debtCollections && debtCollections.Details.length > 0 ? (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>Debt Collections</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHead={[
                                        'Account No.',
                                        'Industry No.',
                                        'Employer',
                                        'Company',
                                        'Amount',
                                        'Contact',
                                        'Status',
                                        'Status Date'
                                    ]}
                                    tableData={debtCollections.Details.map((collection) => {
                                        return [
                                            collection.accountNumber !== '' ? collection.accountNumber : '-',
                                            collection.industryNumber !== '' ? collection.industryNumber : '-',
                                            collection.employer !== '' ? collection.employer : '-',
                                            collection.company !== '' ? collection.company : '-',
                                            collection.amount !== '' ? collection.amount : '-',
                                            collection.contact !== '' ? collection.contact : '-',
                                            collection.status !== '' ? collection.status : '-',
                                            collection.statusDate !== '' ? collection.statusDate : '-'
                                        ];
                                    })}
                                />
                            </CardBody>
                        </Card>
                    </Grid>
                ) : (
                    ''
                )}

                {props.directorInformation.PreviousEnquiries &&
                    props.directorInformation.PreviousEnquiries.length > 0 && (
                        <Grid item xs={12} style={{ marginBottom: 20 }}>
                            <PreviousEnquiriesComponent enquiries={props.directorInformation.PreviousEnquiries} />
                        </Grid>
                    )}

                {showReasonsForScore && (
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                className="no-margin"
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Reasons for score:
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div style={{ flexDirection: 'column' }}>
                                    {Object.keys(props.directorInformation.RiskEvaluation).map((key, index) => (
                                        <p style={{ marginTop: index === 0 ? 0 : 5, fontSize: 12 }} key={index}>
                                            {/* @ts-ignore */}
                                            {props.directorInformation.RiskEvaluation[key]}
                                        </p>
                                    ))}
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                )}

                {hasPublicDefaults && (
                    <Grid item xs={12}>
                        <Card>
                            <Box paddingBottom={3}>
                                <CardHeader color="danger">
                                    <h4 className={classes.cardTitleWhite}>Defaults</h4>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        tableHead={[
                                            'Subscriber Code',
                                            'Branch Name',
                                            'Account Reference No.',
                                            'Default Amount',
                                            'Legal Action',
                                            'Default Date'
                                        ]}
                                        tableData={PublicDefaults.map((record) => {
                                            return [
                                                record.FK_SUBSCR_CODE,
                                                record.BRANCH_NAME,
                                                record.ACCOUNT_REF_NUMBER,
                                                formatCurrency(Number.parseFloat(record.DEFAULT_AMOUNT)),
                                                record.LEGAL_ACTION_DESC,
                                                record.DEFAULT_DATE
                                            ];
                                        })}
                                    />
                                </CardBody>
                            </Box>
                        </Card>
                    </Grid>
                )}

                {hasCreditFacilities && (
                    <Grid item xs={12}>
                        <Card>
                            <Box paddingBottom={3}>
                                <CardHeader color="info">
                                    <h4 className={classes.cardTitleWhite}>Credit Accounts/Facilities</h4>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        tableHead={[
                                            'Type',
                                            'Credit Provider',
                                            'Account Type',
                                            'Status',
                                            'Instalment Amount',
                                            'Current Balance',
                                            'Opening Balance',
                                            'Payment History',
                                            'Last Payment'
                                        ]}
                                        tableData={creditFacilities.map((fac) => {
                                            return [
                                                fac.type,
                                                fac.creditProvider,
                                                fac.accountType,
                                                fac.status,
                                                formatCurrency(fac.instalmentAmount),
                                                formatCurrency(fac.currentBalance),
                                                formatCurrency(fac.openingBalance),
                                                fac.paymentHistory.split('').join(' '),
                                                fac.lastPayment
                                            ];
                                        })}
                                        metaData={props.flaggedFacilities || []}
                                    />
                                </CardBody>
                            </Box>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default DirectorDetailsComponent;

interface IProps {
    directorInformation: IDirectorInformation;
    flaggedFacilities: any[];
}

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'; import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '../Table/Table';
import './ExpansionTableRow.css';
import { ICounterPartRiskDashboard } from '../../models/OverviewModel';
import useAxios from '../../helpers/useAxios';
interface ICombinedLinkage {
    directorEmails: string[];
    directorIds: string[];
    directorNames: string[];
    juristicEntityId: string;
    juristicEntityName: string;
}
interface INeo4jEntity {
    directorId: string;
    directorName: string
    juristicEntityId: number;
    juristicEntityName: string;
}
interface LinkWithHoverEffectProps {
    href: string;
    children: React.ReactNode;
}

const sanitizeString = (str) => str.trim().toLowerCase();

const checkDataDiscrepancy = (
    counterPartyRiskInformation: ICounterPartRiskDashboard,
    neo4jData: INeo4jEntity[]
): boolean => {
    const juristicEntities = counterPartyRiskInformation["DeviceFingerprintExistsOnOtherUser"]?.juristicEntities || [];

    if (juristicEntities.length !== neo4jData.length) {
        return true;
    }

    const neo4jMap = new Map(neo4jData.map(item => [
        `${item.juristicEntityId}-${sanitizeString(item.directorId)}`, {
            ...item,
            directorName: sanitizeString(item.directorName),
            juristicEntityName: sanitizeString(item.juristicEntityName)
        }
    ]));

    for (const entity of juristicEntities) {
        const key = `${entity.juristicEntityId}-${sanitizeString(entity.directorId)}`;
        const neo4jEntity = neo4jMap.get(key);

        if (!neo4jEntity
            || sanitizeString(neo4jEntity.directorName) !== sanitizeString(entity.directorName)
            || sanitizeString(neo4jEntity.juristicEntityName) !== sanitizeString(entity.juristicEntityName)) {
            return true;
        }
    }

    return false;
};




const CounterPartyExpansion: React.FunctionComponent<IProps> = (props) => {
    const counterPartyRisk = _.cloneDeep(props.counterPartyRiskInformation);
    const [hasDiscrepancy, setHasDiscrepancy] = useState(false)
    delete (counterPartyRisk as any).AdditionalInfo;
    delete (counterPartyRisk as any).UpdatedOn;

    const allCounterPartyRiskChecksPassed = _.every(counterPartyRisk, ['isLinked', false]);

    const riskDetails: any[][] = [];

    const [{ data: graphData }, execute] = useAxios(
        { url: `/dashboard/organization/graph` },
        { manual: true }
    );

    useEffect(() => {
        if (props.counterPartyRiskInformation.DeviceFingerprintExistsOnOtherUser && props.counterPartyRiskInformation.DeviceFingerprintExistsOnOtherUser.isLinked) {
            execute();
        }
    }, [props.counterPartyRiskInformation, execute]);

    useEffect(() => {
        if (graphData && graphData.length > 0) {
            const difference = checkDataDiscrepancy(props.counterPartyRiskInformation, graphData);
            setHasDiscrepancy(difference);
        }
    }, [graphData, props.counterPartyRiskInformation]);

    console.log("props.counterPartyRiskInformation", props.counterPartyRiskInformation.DeviceFingerprintExistsOnOtherUser)

    _.forEach(props.counterPartyRiskInformation, (value, key) => {
        if (!_.isString(value)) {
            const combinedValues: ICombinedLinkage[] = [];
            const groupedValue = _.groupBy(value.juristicEntities, (o) => o.juristicEntityId);

            _.forEach(groupedValue, (groupedEntities, index) => {
                combinedValues.push({
                    directorEmails: _.chain(groupedEntities)
                        .map((entity) => entity.directorEmail)
                        .compact()
                        .uniq()
                        .value(),
                    directorIds: _.chain(groupedEntities)
                        .map((entity) => entity.directorId)
                        .compact()
                        .uniq()
                        .value(),
                    directorNames: _.chain(groupedEntities)
                        .map((entity) => entity.directorName)
                        .compact()
                        .uniq()
                        .value(),
                    juristicEntityId: index,
                    juristicEntityName: groupedEntities[0].juristicEntityName,
                });
            });


            let includeOrganizationHeading = false;
            let includeDirectorHeading = false;
            let includeEmailHeading = false;
            const tableHead: string[] = [];

            combinedValues.forEach((entry) => {
                if ((entry.juristicEntityName || entry.juristicEntityId) && !includeOrganizationHeading) {
                    tableHead.push('Organization');
                    includeOrganizationHeading = true;
                }
                if ((entry.directorNames.length > 0 || entry.directorIds.length > 0) && !includeDirectorHeading) {
                    tableHead.push('Directors');
                    includeDirectorHeading = true;
                }
                if (entry.directorEmails.length > 0 && !includeEmailHeading) {
                    tableHead.push('Emails');
                    includeEmailHeading = true;
                }
            });

            const tableData = combinedValues.map((je) => {
                if (je.juristicEntityId !== 'null') {
                    return [
                        `${je.juristicEntityName ? je.juristicEntityName : 'Not Defined'} (${je.juristicEntityId})`,
                        `${je.directorNames.join(', ')}`,
                        `${je.directorEmails.join(', ')}`,
                    ];
                }
                return [];
            }).filter(row => row.length > 0);
            const LinkWithHoverEffect: React.FC<LinkWithHoverEffectProps> = ({ href, children }) => {
                const [isHovered, setIsHovered] = useState(false);
                const linkStyle: React.CSSProperties = {
                    textDecoration: isHovered ? 'underline' : 'none',
                    cursor: 'pointer',
                };
                return (
                    <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        style={linkStyle}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {children}
                    </a>
                );
            };
            let additionalComponents = [];
            if (key === "DeviceFingerprintExistsOnOtherUser" && value.isLinked && props.link) {
                additionalComponents.push(
                    <LinkWithHoverEffect href={props.link}>View Graph</LinkWithHoverEffect>
                );
            }
            if (key === "DeviceFingerprintExistsOnOtherUser" && hasDiscrepancy) {
                additionalComponents.push(<div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    <ErrorOutlineOutlinedIcon style={{ color: red[500] }} />
                    <span style={{ color: 'red', fontWeight: 'bold' }}>Data is outdated</span>
                </div>)
            }

            riskDetails.push([
                _.startCase(key),
                value.isLinked === false ? (
                    <CheckCircleIcon style={{ color: green[500] }} />
                ) : (
                    <CancelIcon color="error" />
                ),
                value.isLinked === false ? '' : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                            {additionalComponents.map((component, index) => (
                                <div key={index}>{component}</div>
                            ))}
                        </div>
                        <Table
                            nested
                            tableData={tableData}
                            tableHead={tableHead}
                            tableTitle="Passed Counter-Party Risk Checks"
                        />
                    </>
                ),
            ]);
        }
    });

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                className="no-margin"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Table
                    tableCellStyle={{ border: 0, fontSize: 16 }}
                    bold={false}
                    tableStyle={{ marginTop: 0 }}
                    tableData={[
                        [
                            'Passed Counter-Party Risk Checks',
                            allCounterPartyRiskChecksPassed ? (
                                <CheckCircleIcon style={{ color: green[500], float: 'right' }} />
                            ) : (
                                <CancelIcon color="error" style={{ float: 'right' }} />
                            ),
                        ],
                    ]}
                />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Table tableHead={['Check', 'Passed', 'Matches']} tableData={riskDetails} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default CounterPartyExpansion;
interface IProps {
    counterPartyRiskInformation: ICounterPartRiskDashboard;
    link?: string
}
import { Box, Chip, Grid, Icon } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React from 'react';
import { IDirectorAccountConfig } from '../../constants/types';
import formatCurrency from '../../helpers/currencyFormatHelper';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import { ConvertToNewSystem, ICreditFacility, IDirectorInformation } from '../../models/OverviewModel';
import DirectorDetailsComponent from '../Dashboard/DirectorInformation/DirectorDetailsComponent';

interface IBand {
    start: number;
    end: number;
    color: string;
}

const creditBands: IBand[] = [
    { start: 0, end: 594, color: '#d13425' },
    { start: 595, end: 610, color: '#db5d3d' },
    { start: 611, end: 628, color: '#ffb32f' },
    { start: 629, end: 659, color: '#71c07c' },
    { start: 660, end: 750, color: '#42a257' }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            boxShadow: 'none'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15)
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary
        },
        icon: {
            verticalAlign: 'bottom',
            height: 20,
            width: 20
        },
        details: {
            alignItems: 'center'
        },
        column: {
            flexBasis: '9.09%'
        },
        helper: {
            borderLeft: `2px solid ${theme.palette.divider}`,
            padding: theme.spacing(1, 2)
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    })
);

const ExpansionTableRow: React.FC<IProps> = (props) => {
    const [copiedName, setCopiedName] = React.useState(false);
    const [copiedIdNumber, setCopiedIdNumber] = React.useState(false);

    // eslint-disable-next-line
    const [valueName, copyName] = useCopyToClipboard();
    const [valueIdNumber, copyIdNumber] = useCopyToClipboard();

    const classes = useStyles();

    const {
        FullName,
        CreditScore,
        Status,
        Properties,
        DirectorAdverseInformation,
        BalanceExposure,
        MonthlyInstalment,
        CumulativeArrears,
        PreviousEnquiries,
        Age,
        IsApplicant,
        IsDirector,
        IsFounder,
        IsSecretary,
        PublicDefaults,
        IdNumber
    } = props.directorInformation;

    let age = 'Unknown';
    if (Age > 0) {
        age = `${Age}`;
    }

    let redFlags: number = 0;

    const adverse = ConvertToNewSystem(DirectorAdverseInformation.adverse);
    const debtRestructuring = ConvertToNewSystem(DirectorAdverseInformation.debtRestructuring);
    const fraudAlerts = ConvertToNewSystem(DirectorAdverseInformation.fraudAlerts);
    const judgements = ConvertToNewSystem(DirectorAdverseInformation.judgements);
    const notices = ConvertToNewSystem(DirectorAdverseInformation.notices);
    const debtCollections = ConvertToNewSystem(DirectorAdverseInformation.debtCollections);

    redFlags += adverse.Count;
    redFlags += debtRestructuring.Count;
    redFlags += fraudAlerts.Count;
    redFlags += judgements.Count;
    redFlags += notices.Count;
    redFlags += debtCollections.Count;

    if (props.directorInformation.IdentificationStatus === 'Deceased') {
        redFlags += 1;
    }

    redFlags += DirectorAdverseInformation.sasfaIndicators ?? 0;

    const isDeceased =
        props.directorInformation.DeceasedStatus === 'Deceased' ||
        props.directorInformation.DeceasedAccordingToLinkedCompany;

    if (isDeceased) {
        redFlags += 1;
    }

    if (PublicDefaults && PublicDefaults.length > 0) {
        redFlags += PublicDefaults.length;
    }

    let flaggedFacilities: ICreditFacility[] = [];

    if (props.accountConfig) {
        flaggedFacilities = props.directorInformation.CreditFacilities.filter((facility) => {
            const creditProvider = facility.creditProvider.toLowerCase();

            const accountType = facility.accountType.toLowerCase();

            let flagAccount = false;

            if (facility.soldToThirdParty) {
                flagAccount = true;
            }

            if (!flagAccount) {
                const creditProviders = props.accountConfig.creditProviders;
                for (let i = 0; i < creditProviders.length; i++) {
                    if (creditProvider.includes(creditProviders[i])) {
                        flagAccount = true;
                        break;
                    }
                }
            }

            if (!flagAccount) {
                const accountTypes = props.accountConfig.accountTypes;
                for (let i = 0; i < accountTypes.length; i++) {
                    if (accountType.includes(accountTypes[i])) {
                        flagAccount = true;
                        break;
                    }
                }
            }

            return flagAccount;
        });
    }

    if (flaggedFacilities.length > 0) {
        redFlags += flaggedFacilities.length;
    }

    let creditColor = creditBands[0].color;

    try {
        const creditScore = parseInt(CreditScore.split(' -')[0], 10);
        const matchingBand = _.find(creditBands, (o) => o.start <= creditScore && o.end >= creditScore);

        if (!_.isNil(matchingBand)) {
            creditColor = matchingBand.color;
        }
    } catch (err) {}

    const name = FullName.toLowerCase().replace(' (applicant)', '');

    const fullName = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

    return (
        <div className={classes.root}>
            <Divider />
            <ExpansionPanel square style={{ boxShadow: 'none' }}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                >
                    <Grid container style={{ width: '100%' }}>
                        <Grid item md={3} style={{ textTransform: 'capitalize' }}>
                            <Box flexDirection="column">
                                <Box style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 30 }}>
                                    <span>{fullName}</span>
                                    <div
                                        onClick={(event) => {
                                            if (!copiedName) {
                                                copyName(fullName);
                                                setCopiedName(true);
                                                setTimeout(() => {
                                                    setCopiedName(false);
                                                }, 1500);
                                            }

                                            event.stopPropagation();
                                        }}
                                    >
                                        {copiedName ? (
                                            <Icon fontSize="small">checkbox</Icon>
                                        ) : (
                                            <Icon fontSize="small">content_copy</Icon>
                                        )}
                                    </div>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', paddingRight: 30 }}>
                                    <span>{IdNumber}</span>
                                    <div
                                        onClick={(event) => {
                                            if (!copiedIdNumber) {
                                                copyIdNumber(IdNumber);
                                                setCopiedIdNumber(true);
                                                setTimeout(() => {
                                                    setCopiedIdNumber(false);
                                                }, 1500);
                                            }

                                            event.stopPropagation();
                                        }}
                                    >
                                        {copiedIdNumber ? (
                                            <Icon fontSize="small">checkbox</Icon>
                                        ) : (
                                            <Icon fontSize="small">content_copy</Icon>
                                        )}
                                    </div>
                                </Box>
                                <Box style={{ marginTop: '5px', marginBottom: '2px' }} alignItems="center">
                                    {IsApplicant && (
                                        <Chip
                                            label="Applicant"
                                            style={{
                                                backgroundColor: green[500],
                                                color: 'white',
                                                fontWeight: 'bold'
                                            }}
                                            size="small"
                                        />
                                    )}
                                    {IsFounder && (
                                        <Chip
                                            label="Founder"
                                            color="default"
                                            style={{
                                                fontWeight: 'bold',
                                                marginLeft: IsApplicant ? '5px' : '0px'
                                            }}
                                            size="small"
                                        />
                                    )}
                                    {IsDirector === false && (
                                        <Chip
                                            label="Non-Director"
                                            color="default"
                                            style={{
                                                fontWeight: 'bold',
                                                marginLeft: IsApplicant || IsFounder ? '5px' : '0px'
                                            }}
                                            size="small"
                                        />
                                    )}
                                    {IsSecretary === true && (
                                        <Chip
                                            label="Secretary"
                                            color="default"
                                            style={{
                                                fontWeight: 'bold',
                                                marginLeft:
                                                    IsApplicant || IsFounder || IsDirector === false ? '5px' : '0px'
                                            }}
                                            size="small"
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={1}>
                            {age}
                        </Grid>
                        <Grid item md={1} style={{ color: creditColor }}>
                            {CreditScore.split(' -')[0]}
                        </Grid>
                        <Grid item md={1}>
                            {!!Status && (
                                <Chip
                                    label={Status}
                                    style={{
                                        backgroundColor: Status === 'ACTIVE' ? green[500] : 'orange',
                                        color: 'white',
                                        fontWeight: 'bold'
                                    }}
                                    size="small"
                                />
                            )}
                        </Grid>
                        <Grid item md={1}>
                            {Properties}
                        </Grid>
                        <Grid item md={1}>
                            {redFlags > 0 ? (
                                <div style={{ float: 'left' }}>
                                    <div className="numberCircle">{redFlags}</div>
                                </div>
                            ) : (
                                0
                            )}
                        </Grid>
                        <Grid item md={1}>
                            {formatCurrency(BalanceExposure)}
                        </Grid>
                        <Grid item md={1}>
                            {formatCurrency(MonthlyInstalment)}
                        </Grid>
                        <Grid item md={1}>
                            {formatCurrency(CumulativeArrears)}
                        </Grid>
                        <Grid item md={1}>
                            {PreviousEnquiries ? PreviousEnquiries.length : null}
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <Divider variant="middle" />
                <ExpansionPanelDetails className={classes.details}>
                    <DirectorDetailsComponent
                        directorInformation={props.directorInformation}
                        flaggedFacilities={flaggedFacilities.map((facility) => ({
                            entry: [
                                facility.type,
                                facility.creditProvider,
                                facility.accountType,
                                facility.status,
                                formatCurrency(facility.instalmentAmount),
                                formatCurrency(facility.currentBalance),
                                formatCurrency(facility.openingBalance),
                                facility.paymentHistory.split('').join(' '),
                                facility.lastPayment
                            ],
                            soldToThirdParty: { sold: facility.soldToThirdParty, to: facility.thirdPartyName }
                        }))}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

export default ExpansionTableRow;

interface IProps {
    directorInformation: IDirectorInformation;
    companyStartDate: Date;
    accountConfig: IDirectorAccountConfig;
}

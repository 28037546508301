import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, IconButton } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import _ from 'lodash';
import React from 'react';
import { useRecoilState } from 'recoil';
import { cipcStatusModalAtom } from '../../../atoms';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { IApplicantDashboard, IDirectorInformation, IFraudStatus, ILink, IStatus } from '../../../models/OverviewModel';
import Table from '../../Table/Table';
import { DirectorEntry } from './components';

interface ICombinedLinkage {
    juristicEntityId: string;
    juristicEntityName: string;
    directorNames: string[];
    directorIds: string[];
}

interface IKeyMatch {
    [key: string]: string;
}

const keyMatch: IKeyMatch = {
    AcccountingSoftwareConnectedForAnotherAccount: `Accounting software was connected for another application`,
    AccountingSoftwareConnectedForAnotherAccount: `Accounting software was connected for another application`,
    ApplicantCompanyNameMatchesCreditReport: `Applicant's company name matches credit bureau trading name`,
    ApplicantCompanyNameMatchesCreditReportLegalName: `Applicant's company name matches credit bureau legal name`,
    ApplicantDeceased: `Applicant is not marked as deceased`,
    ApplicantEmailIsNotAGenericDomain: `Applicant's email is not a generic domain`,
    ApplicantFirstInitialMatchesCreditReport: `Applicant's first initial matches that on credit bureau`,
    ApplicantFirstNameMatchesCreditReport: `Applicant's first name matches that on credit bureau`,
    ApplicantMobileNumberAppearsOnMatchingDirectorReport:
        'Applicants mobile number appears on matching directors credit report',
    ApplicantMobileNumberDoesNotAppearOnOtherDirectorReports:
        'Applicants mobile number does not appear on other directors reports',
    ApplicantSurnameMatchesCreditReport: `Applicant's surname matches that on credit bureau`,
    CompanyCIPCStatusIsGood: `Company CIPC status is good`,
    DirectorStatusActive: `Applicant's director status is active`,
    ForeignId: `Applicant's is NOT a foreigner (has SA ID)`,
    IdNumberListedAsDirector: `Applicant ID listed as a director`,
    RelatedCompanyCIPCStatusesAreallGood: `Related company CIPC statuses are all good`,
    ValidIdNumber: `Applicant's ID is a valid SA ID`
};

const reversals = ['ForeignId', 'ApplicantDeceased'];

type CIPCStatusEntry = {
    [key: string]: {
        companyName: string;
        registrationNumber: string;
        status: string;
    }[];
};

const ApplicantFraudExpansion: React.FunctionComponent<IProps> = (props) => {
    const [showCIPCStatusModal, setShowCIPCStatusModal] = useRecoilState(cipcStatusModalAtom);

    const handleClose = () => setShowCIPCStatusModal(false);

    const { width, height } = useWindowDimensions();

    const highConfidenceRiskDetails: (string | JSX.Element)[][] = [];
    const lowConfidenceRiskDetails: (string | JSX.Element)[][] = [];

    let numberOfHighConfidenceRiskFailures = 0;
    let numberOfLowConfidenceRiskFailures = 0;
    _.forEach(props.applicantFraudData, (value, key) => {
        if (isLink(value)) {
            const combinedValues: ICombinedLinkage[] = [];
            const groupedValue = _.groupBy(value.juristicEntities, (o) => o.juristicEntityId);

            _.forEach(groupedValue, (val, ind) => {
                combinedValues.push({
                    juristicEntityId: ind,
                    juristicEntityName: val[0].juristicEntityName,
                    directorIds: val.map((k) => (k.directorId ? k.directorId : '')),
                    directorNames: val.map((k) => (k.directorName ? k.directorName : ''))
                });
            });

            highConfidenceRiskDetails.push([
                keyMatch[key],
                value.isLinked === false ? (
                    <CheckCircleIcon style={{ color: green[500] }} />
                ) : (
                    <CancelIcon color="error" />
                ),
                value.isLinked === false ? (
                    ''
                ) : (
                    <Table
                        tableHead={['Organization', 'Directors']}
                        tableData={_.compact(
                            combinedValues.map((je) => {
                                if (!_.isNil(je.juristicEntityId) && je.juristicEntityId !== 'null') {
                                    return [
                                        `${je.juristicEntityName ? je.juristicEntityName : 'Not Defined'} (${
                                            je.juristicEntityId
                                        })`,
                                        `${je.directorNames.join(', ')}`
                                    ];
                                }
                                return [];
                            })
                        )}
                    />
                )
            ]);
        } else if (isStatus(value)) {
            if (value.isCorrect === false) {
                numberOfHighConfidenceRiskFailures += 1;
            }

            highConfidenceRiskDetails.push([
                keyMatch[key],
                value.isCorrect === true ? (
                    <CheckCircleIcon style={{ color: green[500] }} />
                ) : (
                    <CancelIcon color="error" />
                ),
                value.isCorrect === true ? '' : value.statuses.join(', ')
            ]);
        } else if (isFraudStatus(value)) {
            let passedCheck = _.clone(value.passedCheck);

            if (reversals.includes(key)) {
                passedCheck = !value.passedCheck;
            }
            if (passedCheck === false) {
                if (value.confidence === 'high') {
                    numberOfHighConfidenceRiskFailures += 1;
                } else {
                    numberOfLowConfidenceRiskFailures += 1;
                }
            }

            if (value.confidence === 'high') {
                highConfidenceRiskDetails.push([
                    keyMatch[key],
                    passedCheck === true ? (
                        <CheckCircleIcon style={{ color: green[500] }} />
                    ) : (
                        <CancelIcon color="error" />
                    )
                ]);
            } else {
                lowConfidenceRiskDetails.push([
                    keyMatch[key],
                    passedCheck === true ? (
                        <CheckCircleIcon style={{ color: green[500] }} />
                    ) : (
                        <CancelIcon color="error" />
                    )
                ]);
            }
        } else if (isBoolean(value)) {
            if (reversals.includes(key)) {
                value = !value;
            }
            if (value === false) {
                numberOfHighConfidenceRiskFailures += 1;
            }

            highConfidenceRiskDetails.push([
                keyMatch[key],
                value === true ? <CheckCircleIcon style={{ color: green[500] }} /> : <CancelIcon color="error" />
            ]);
        }
    });

    const directors: string[] = [];

    let directorEntries: CIPCStatusEntry[] = [];

    if (props.applicantFraudData.RelatedCompanyCIPCStatusesAreallGood.statuses.length > 0) {
        for (let i = 0; i < props.applicantFraudData.RelatedCompanyCIPCStatusesAreallGood.statuses.length; i++) {
            const entry = props.applicantFraudData.RelatedCompanyCIPCStatusesAreallGood.statuses[i];

            const items = entry.split(' | ');

            const name = items[2].slice(0, items[2].length);

            if (!directors.includes(name)) {
                directors.push(name);
            }
        }

        if (directors.length > 0) {
            for (let i = 0; i < directors.length; i++) {
                const director = directors[i];

                const entries: {
                    companyName: string;
                    registrationNumber: string;
                    status: string;
                }[] = [];

                for (
                    let j = 0;
                    j < props.applicantFraudData.RelatedCompanyCIPCStatusesAreallGood.statuses.length;
                    j++
                ) {
                    const entry = props.applicantFraudData.RelatedCompanyCIPCStatusesAreallGood.statuses[j];

                    const items = entry.split(' | ');

                    const name = items[2].slice(0, items[2].length);

                    if (director === name) {
                        entries.push({
                            companyName: items[0].slice(0, items[0].indexOf('(') - 1),
                            registrationNumber: items[0].slice(items[0].indexOf('(') + 1, items[0].indexOf(')')),
                            status: items[1]
                        });
                    }
                }

                directorEntries.push({
                    [director]: entries
                });
            }
        }
    }

    if (directorEntries.length > 1) {
        directorEntries = directorEntries.sort((a, b) => Object.values(b)[0].length - Object.values(a)[0].length);
    }

    return (
        <>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    className="no-margin"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Table
                        tableCellStyle={{
                            border: 0,
                            fontSize: 16
                        }}
                        bold={false}
                        tableStyle={{ marginTop: 0 }}
                        tableData={[
                            [
                                'High Confidence Fraud Data',
                                numberOfHighConfidenceRiskFailures === 0 ? (
                                    <div style={{ float: 'right' }}>
                                        <CheckCircleIcon style={{ color: green[500], paddingTop: 7 }} />
                                    </div>
                                ) : (
                                    <div style={{ float: 'right' }}>
                                        <div className="numberCircle">{numberOfHighConfidenceRiskFailures}</div>
                                    </div>
                                )
                            ]
                        ]}
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table tableHead={['Check', 'Result', 'Matches']} tableData={highConfidenceRiskDetails} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    className="no-margin"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Table
                        tableCellStyle={{
                            border: 0,
                            fontSize: 16
                        }}
                        bold={false}
                        tableStyle={{ marginTop: 0 }}
                        tableData={[
                            [
                                'Low Confidence Fraud Data',
                                numberOfLowConfidenceRiskFailures === 0 ? (
                                    <div style={{ float: 'right' }}>
                                        <CheckCircleIcon style={{ color: green[500], paddingTop: 7 }} />
                                    </div>
                                ) : (
                                    <div style={{ float: 'right' }}>
                                        <div className="numberCircle">{numberOfLowConfidenceRiskFailures}</div>
                                    </div>
                                )
                            ]
                        ]}
                    />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table tableHead={['Check', 'Result', 'Matches']} tableData={lowConfidenceRiskDetails} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Modal open={showCIPCStatusModal} onClose={handleClose}>
                <Box
                    sx={{
                        'border-radius': 5,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        left: '50%',
                        maxHeight: height * 0.6,
                        minWidth: width * 0.45,
                        overflowY: 'scroll',
                        p: 5,
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <Box
                        display="flex"
                        style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 25
                        }}
                    >
                        <h6
                            style={{
                                marginBottom: 0,
                                marginTop: 0
                            }}
                        >
                            Related Company CIPC Status Issues
                        </h6>
                        <IconButton size="medium" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {directorEntries.length > 0 &&
                        directorEntries.map((entry) => {
                            const name = Object.keys(entry)[0];

                            let Status = '';

                            const director = props.directorInformation.find((director) => {
                                let fullName = '';

                                if (director.FullName.includes(' (APPLICANT)')) {
                                    fullName = director.FullName.slice(0, director.FullName.indexOf(' (APPLICANT)'));
                                } else {
                                    fullName = director.FullName;
                                }

                                return fullName.toUpperCase() === name;
                            });

                            if (director) {
                                Status = director.Status;
                            }

                            return <DirectorEntry entry={entry} status={Status} />;
                        })}
                </Box>
            </Modal>
        </>
    );
};

const isLink = (value: ILink | IStatus | IFraudStatus | boolean): value is ILink => {
    if (_.isBoolean(value)) {
        return false;
    }

    return (value as ILink).isLinked !== undefined;
};

const isStatus = (value: ILink | IStatus | IFraudStatus | boolean): value is IStatus => {
    if (_.isBoolean(value)) {
        return false;
    }

    return (value as IStatus).isCorrect !== undefined;
};

const isFraudStatus = (value: ILink | IStatus | IFraudStatus | boolean): value is IFraudStatus => {
    if (_.isBoolean(value)) {
        return false;
    }

    return (value as IFraudStatus).passedCheck !== undefined;
};

const isBoolean = (value: ILink | IStatus | IFraudStatus | boolean): value is boolean => {
    if (_.isBoolean(value)) {
        return true;
    }

    return false;
};

export default ApplicantFraudExpansion;

interface IProps {
    applicantFraudData: IApplicantDashboard;
    directorInformation: IDirectorInformation[];
}

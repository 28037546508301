/*eslint-disable*/
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
// core components
import styles from '../../assets/jss/material-dashboard-react/components/footerStyle';

const useStyles = makeStyles(styles);

export default function Footer() {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <p className={classes.right}>
                    <span>
                        &copy; {new Date().getFullYear()}{' '}
                        <a href="https://bridgement.com" target="_blank" className={classes.a}>
                            Bridgement
                        </a>
                    </span>
                </p>
            </div>
        </footer>
    );
}

import Axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import configuration from '../environment';

const axios = Axios.create({
    baseURL: `${configuration.hostUrl}`,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'XSRF-TOKEN',
    headers: {
        'Access-Control-Allow-Origin': `${configuration.hostUrl}`,
        'Access-Control-Allow-Headers': 'cognito-auth, Content-Type, Accept, XSRF-TOKEN',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
    }
});

axios.defaults.withCredentials = true;

const getCookie = (name: string): string | null => {
    var nameEQ = name + '=';

    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) === ' ') c = c.substring(1, c.length);

        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }

    return null;
};

axios.interceptors.request.use((config) => {
    const authCookie = getCookie('authToken');
    config.headers['cognito-auth'] = authCookie;
    config.withCredentials = true;
    return config;
});

const useAxios = makeUseAxios({ axios });

export default useAxios;

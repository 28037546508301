import React, { ReactNode } from 'react';
import _ from 'lodash';
// nodejs library to set properties for components
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles: IStyles = {
    grid: {
        padding: '0 15px !important'
    }
};

interface IStyles {
    grid: IGrid;
}

interface IGrid {
    padding?: string;
    [key: string]: any;
}


export default function GridItem(props: IProps) {
    const { children, padding, ...rest } = props;

    if (!_.isNil(padding)) {
        styles.grid.padding = padding;
    }

    const useStyles = makeStyles(styles);
    const classes = useStyles();
    return (
        <Grid item {...rest} className={classes.grid}>
            {children}
        </Grid>
    );
}

interface IProps {
    children: ReactNode;
    padding?: string;
    [key: string]: any;
}

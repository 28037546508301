import * as dateFns from 'date-fns';

import React, { useEffect, useState } from 'react';

import { ChartDataSets } from 'chart.js';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { IDirectorInformation } from '../../../models/OverviewModel';
import useTimeline from '../Timeline/useTimeline';

const backgroundColors = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];

const DirectorCompanyTimeline: React.FunctionComponent<IProps> = (props) => {
    const [companyGraphData, setCompanyGraphData] = useState<any>({});
    const [companyGraphLabels, setCompanyLabels] = useState<string[]>([]);

    const directorCompanyRef = useTimeline(companyGraphData, companyGraphLabels, props.directors, props.minimumDate);

    const { height } = useWindowDimensions();

    let canvasHeight: number | undefined = companyGraphLabels.length * 10;

    if (canvasHeight < 100) {
        canvasHeight = undefined;
    }

    useEffect(() => {
        const dataSets: ChartDataSets[] = [];

        let relatedCompanies: { companyName: string; directorName: string }[] = [];

        props.directors.forEach((director) => {
            director.LinkedCompanyInfo.forEach((company) => {
                relatedCompanies.push({ companyName: company.name, directorName: director.FullName });
            });
        });

        relatedCompanies.sort((a, b) => a.companyName.localeCompare(b.companyName));

        let directorIndex = 0;

        props.directors.forEach((director) => {
            const linkedCompanies = director.LinkedCompanyInfo;

            const data = new Array(relatedCompanies.length).fill(undefined);

            const backgroundColor = backgroundColors[directorIndex];
            if (directorIndex === backgroundColors.length - 1) {
                directorIndex = 0;
            } else {
                directorIndex += 1;
            }

            relatedCompanies.forEach((company, index) => {
                const matchingCompany = linkedCompanies.find(
                    (linkedCompany) => linkedCompany.name === company.companyName
                );

                if (company.directorName === director.FullName && matchingCompany) {
                    data[index] = [
                        dateFns.parse(matchingCompany.startDate, 'dd/MM/yyyy', new Date()),
                        dateFns.parse(matchingCompany.endDate, 'dd-MM-yyyy', new Date())
                    ];
                }
            });

            dataSets.push({
                data,
                label: director.FullName,
                backgroundColor
            });
        });

        const reducedLabels = relatedCompanies.map((company) => {
            return company.companyName;
        });

        setCompanyGraphData(dataSets);
        setCompanyLabels(reducedLabels);
    }, [props.directors, props.minimumDate]);

    return (
        <div
            style={{
                maxHeight: height * 0.75,
                overflowY: 'scroll',
                paddingBottom: '25px',
                paddingRight: '10px',
                scrollbarWidth: 'thin',
                width: '100%'
            }}
        >
            <canvas height={canvasHeight} ref={directorCompanyRef} />
        </div>
    );
};

interface IProps {
    minimumDate?: Date;
    directors: IDirectorInformation[];
}

export default DirectorCompanyTimeline;

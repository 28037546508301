import React from 'react';

import Icon from '@material-ui/core/Icon';

import IconListExpansion from '../../Expansion/IconListExpansion';
import { IDirectorInformation } from '../../../models/OverviewModel';
import DirectorCompanyTimeline from '../../Graph/DirectorCompanyTimeline/DirectorCompanyTimeline';
import DirectorChanges from '../DirectorChanges/DirectorChanges';

const DirectorRelatedCompanyTimelinesComponent: React.FC<IProps> = (props) => {
    return (
        <IconListExpansion
            expansionList={[
                <DirectorCompanyTimeline
                    key="director_related_company_timeline"
                    directors={props.directorInformation}
                    minimumDate={props.companyStartDate}
                ></DirectorCompanyTimeline>
            ]}
            title={
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingRight: 5
                        }}
                    >
                        <Icon style={{ fontSize: 20, marginRight: 10 }}>info_outline</Icon> Director Related Company
                        Timelines
                    </div>
                    <DirectorChanges
                        information={props.directorInformation}
                        directorStatusThreshold={6}
                        display="COMPANY"
                        companyThreshold={12}
                    />
                </>
            }
        ></IconListExpansion>
    );
};

export default DirectorRelatedCompanyTimelinesComponent;

interface IProps {
    directorInformation: IDirectorInformation[];
    companyStartDate: Date;
}

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Icon,
    TableBody,
    TableCell,
    TableContainer,
    TableHead
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@mui/material/Box';
import BasicTable from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';

import React from 'react';
import useCopyToClipboard from '../../../../../hooks/useCopyToClipboard';

type CIPCStatusEntry = {
    [key: string]: {
        companyName: string;
        registrationNumber: string;
        status: string;
    }[];
};

const DirectorEntry: React.FunctionComponent<IProps> = (props) => {
    const [copied, setCopied] = React.useState(false);

    // eslint-disable-next-line
    const [value, copy] = useCopyToClipboard();

    const name = Object.keys(props.entry)[0];

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <Box alignItems="center" display="flex">
                            <span>{name}</span>
                            <div
                                style={{ marginLeft: '10px', marginTop: '5px' }}
                                onClick={(event) => {
                                    if (!copied) {
                                        copy(name);
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 2500);
                                    }

                                    event.stopPropagation();
                                }}
                            >
                                {copied ? (
                                    <Icon fontSize="small">checkbox</Icon>
                                ) : (
                                    <Icon fontSize="small">content_copy</Icon>
                                )}
                            </div>
                        </Box>
                        {!!props.status && (
                            <Chip
                                label={props.status}
                                style={{
                                    backgroundColor: props.status === 'ACTIVE' ? green[500] : 'orange',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    marginTop: 8,
                                    marginBottom: 5
                                }}
                                size="small"
                            />
                        )}
                    </div>
                    <div style={{ float: 'left' }}>
                        <div className="numberCircle">{props.entry[name].length}</div>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <TableContainer>
                    <BasicTable aria-label="simple-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell align="right">Registration Number</TableCell>
                                <TableCell align="right">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.entry[name].map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.companyName}
                                    </TableCell>
                                    <TableCell align="right">{row.registrationNumber}</TableCell>
                                    <TableCell align="right">{row.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </BasicTable>
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default DirectorEntry;

interface IProps {
    entry: CIPCStatusEntry;
    status: string;
}

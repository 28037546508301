import * as _ from 'lodash';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Surface,
    Symbols,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { ICreditHistory, IDirectorInformation } from '../../../models/OverviewModel';

import Box from '@mui/material/Box';
import dayjs from 'dayjs';

const retrieveDistinctValues = (value: string, index: number, self: string[]) => {
    return self.indexOf(value) === index;
};

function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 15)];
    }
    return color;
}

const companyRiskLimits = ['625', '640', '654', '666'];

const directorRiskLimits = ['594', '610', '628', '659'];

const CreditScoreTimeline: React.FunctionComponent<IProps> = (props) => {
    const [disabled, setDisabled] = useState<string[] | never[]>(props.directors.map((director) => director.FullName));
    const [alignment, setAlignment] = React.useState('Company');
    const [randomColors] = React.useState(
        props.directors.concat({} as IDirectorInformation).map(() => {
            return getRandomColor();
        })
    );

    let labels: any = [];

    let repeatingDirectors: any = {};

    props.directors.forEach((director) => {
        if (_.isNil(repeatingDirectors[director.FullName])) {
            repeatingDirectors[director.FullName] = 1;
        } else {
            repeatingDirectors[director.FullName] = repeatingDirectors[director.FullName] + 1;
        }
    });

    for (const [key, value] of Object.entries(repeatingDirectors)) {
        // @ts-ignore
        if (value > 1) {
            props.directors.forEach((director) => {
                if (key === director.FullName) {
                    director.FullName += ` (${director.IdNumber})`;
                }
            });
        }
    }

    props.directors.forEach((creditHistory) => {
        const dates = creditHistory.DirectorCreditHistory.map((point) => point.date);
        dates.forEach((date) => labels.push(dayjs(date).format('MM/DD/YYYY')));
    });

    props.companyCreditHistory.forEach((creditHistory) => {
        labels.push(dayjs(creditHistory.date).format('MM/DD/YYYY'));
    });

    labels = labels.filter(retrieveDistinctValues);

    // @ts-ignore
    labels.sort((dateA: string, dateB: string) => new Date(dateA) - new Date(dateB));

    labels.filter((date: string) => dayjs(labels[labels.length - 1]).diff(date, 'year') >= 2);

    labels = labels.map((date: string) => dayjs(date).format('DD/MM/YYYY'));

    labels = labels.map((label: string) => ({
        name: label
    }));

    let entries = [props.companyName];

    const domainOutliers: any[] = [];

    props.companyCreditHistory.forEach((creditHistory) => {
        labels.forEach((label: any) => {
            if (label.name === dayjs(creditHistory.date).format('DD/MM/YYYY')) {
                const score = parseInt(creditHistory.score);
                if (score < 550) {
                    label[props.companyName] = 550;
                    domainOutliers.push({
                        name: label.name,
                        [props.companyName]: score
                    });
                } else if (score > 730) {
                    label[props.companyName] = 730;
                    domainOutliers.push({
                        name: label.name,
                        [props.companyName]: score
                    });
                } else {
                    label[props.companyName] = score;
                }
            }
        });
    });

    props.directors.forEach((creditHistory) => {
        creditHistory.DirectorCreditHistory.forEach((point) => {
            labels.forEach((label: any) => {
                if (label.name === dayjs(point.date).format('DD/MM/YYYY')) {
                    const score = parseInt(point.score);
                    if (score < 550) {
                        label[creditHistory.FullName] = 550;
                        const indexOfObject = domainOutliers.findIndex((object: any) => object.name === label.name);
                        if (indexOfObject !== -1) {
                            domainOutliers[indexOfObject] = {
                                ...domainOutliers[indexOfObject],
                                [creditHistory.FullName]: score
                            };
                        } else {
                            domainOutliers.push({
                                name: label.name,
                                [creditHistory.FullName]: score
                            });
                        }
                    } else if (score > 730) {
                        label[creditHistory.FullName] = 730;
                        const indexOfObject = domainOutliers.indexOf((object: any) => object.name === label.name);
                        if (indexOfObject !== -1) {
                            domainOutliers[indexOfObject] = {
                                ...domainOutliers[indexOfObject],
                                [creditHistory.FullName]: score
                            };
                        } else {
                            domainOutliers.push({
                                name: label.name,
                                [creditHistory.FullName]: score
                            });
                        }
                    } else {
                        label[creditHistory.FullName] = score;
                    }
                }
            });
        });
        entries.push(creditHistory.FullName);
    });

    const handleClick = (dataKey: any) => {
        if (_.includes(disabled, dataKey)) {
            setDisabled(disabled.filter((key) => key !== dataKey));
        } else if (disabled.length !== entries.length - 1) {
            setDisabled(disabled.concat(dataKey));
        }
    };

    const handleChange = (newAlignment: string, companyDataKey: string, directors: any) => {
        setAlignment(newAlignment);
        if (newAlignment === 'Company') {
            setDisabled(directors.map((item: any) => item.dataKey));
        } else {
            setDisabled([companyDataKey]);
        }
    };

    const renderCustomisedLegend = ({ payload }: any) => {
        const { dataKey: companyDataKey } = payload[0];

        const directors = _.cloneDeep(payload).splice(1, payload.length);

        return (
            <div className="customized-legend" style={{ paddingLeft: 15, paddingTop: 30 }}>
                <React.Fragment>
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={(_, value: string) => handleChange(value, companyDataKey, directors)}
                        size="small"
                    >
                        <ToggleButton value="Company" size="small" color="primary">
                            Company
                        </ToggleButton>
                        <ToggleButton value="Directors" size="small" color="primary">
                            Director{directors.length > 1 ? 's' : ''}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </React.Fragment>
                {alignment !== 'Company' && (
                    <Box sx={{ marginTop: 1 }}>
                        {directors.map((entry: any, index: number) => {
                            const { dataKey, color } = entry;
                            const active = _.includes(disabled, dataKey);
                            const style = {
                                marginTop: index > 0 ? 5 : 15,
                                marginRight: 15,
                                color: active ? '#AAA' : '#000'
                            };
                            return (
                                <div
                                    className="legend-item"
                                    onClick={() => handleClick(dataKey)}
                                    style={style}
                                    key={index}
                                >
                                    <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                                        <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                                        {active && <Symbols cx={5} cy={5} type="circle" size={25} fill={'#FFF'} />}
                                    </Surface>
                                    <span style={{ marginLeft: 5 }}>{dataKey}</span>
                                </div>
                            );
                        })}
                    </Box>
                )}
            </div>
        );
    };

    const minHeight = 340;

    const heightAdjustment = props.directors.length * 50;

    return (
        <ResponsiveContainer width="95%" height={minHeight + heightAdjustment} minHeight={minHeight}>
            <LineChart data={labels}>
                <ReferenceLine
                    y={alignment === 'Company' ? companyRiskLimits[0] : directorRiskLimits[0]}
                    strokeWidth={1}
                    label={{ position: 'insideTopRight', value: 'VERY HIGH', fill: 'black', fontSize: 14 }}
                    strokeDasharray="3 3"
                />
                <ReferenceLine
                    y={alignment === 'Company' ? companyRiskLimits[1] : directorRiskLimits[1]}
                    strokeWidth={1}
                    label={{ position: 'insideTopRight', value: 'HIGH', fill: 'black', fontSize: 14 }}
                    strokeDasharray="3 3"
                />
                <ReferenceLine
                    y={alignment === 'Company' ? companyRiskLimits[2] : directorRiskLimits[2]}
                    strokeWidth={1}
                    label={{ position: 'insideTopRight', value: 'AVERAGE', fill: 'black', fontSize: 14 }}
                    strokeDasharray="3 3"
                />
                <ReferenceLine
                    y={alignment === 'Company' ? companyRiskLimits[3] : directorRiskLimits[3]}
                    strokeWidth={1}
                    label={{ position: 'insideTopRight', value: 'LOW', fill: 'black', fontSize: 14 }}
                    strokeDasharray="3 3"
                />
                <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                <XAxis dataKey="name" dy={10} />
                <YAxis type="number" domain={[550, 730]} />
                <Tooltip
                    formatter={(value: number, name: string, props: any) => {
                        if (domainOutliers.length > 0) {
                            const indexOfObject = domainOutliers.findIndex(
                                (object: any) => object.name === props.payload.name
                            );

                            const outlierDetails = domainOutliers[indexOfObject];

                            if (outlierDetails && name in outlierDetails) {
                                if (outlierDetails[name] < 500) {
                                    return ['Invalid Score', name];
                                } else {
                                    return [outlierDetails[name], name];
                                }
                            } else {
                                return [value, name];
                            }
                        } else {
                            return [value, name];
                        }
                    }}
                />
                <Legend
                    verticalAlign="bottom"
                    align="left"
                    content={renderCustomisedLegend}
                    // @ts-ignore
                    payload={entries.map((entry: string, index: number) => ({
                        dataKey: entry,
                        color: randomColors[index]
                    }))}
                />
                {entries.map(
                    (entry: string, index: number) =>
                        !_.includes(disabled, entry) && (
                            <Line
                                key={index}
                                type="monotone"
                                dataKey={entry}
                                stroke={randomColors[index]}
                                strokeWidth={4}
                                connectNulls
                            />
                        )
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

interface IProps {
    directors: IDirectorInformation[];
    companyCreditHistory: ICreditHistory[];
    companyName: string;
}

export default CreditScoreTimeline;

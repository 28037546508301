import 'date-fns';

import * as dateFns from 'date-fns';

import Accessibility from '@material-ui/icons/Accessibility';
import CancelIcon from '@material-ui/icons/Cancel';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import { IFraudDataDashboard } from '../../../models/OverviewModel';
import { ReactComponent as PDF } from '../../../assets/img/accounting/pdf.svg';
import { ReactComponent as Quickbooks } from '../../../assets/img/accounting/quickbooks.svg';
import React from 'react';
import { ReactComponent as Sage } from '../../../assets/img/accounting/sage.svg';
import Table from '../../Table/Table';
import { ReactComponent as Xero } from '../../../assets/img/accounting/xero.svg';
import { ReactComponent as Yodlee } from '../../../assets/img/accounting/yodlee.svg';
import _ from 'lodash';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle';

const useStyles = makeStyles(styles);

const FraudDataComponent: React.FC<IProps> = (props) => {
    const classes = useStyles();

    const bankStatementSourceImages: JSX.Element[] = [];

    const uniqueSources = _.uniq(props.fraudData.BankStatementSources);

    for (let i = 0; i < uniqueSources.length; i += 1) {
        const source = uniqueSources[i];

        if (source === 'Xero') {
            bankStatementSourceImages.push(<Xero width="100%" height="50px" />);
        } else if (source === 'Sage One') {
            bankStatementSourceImages.push(<Sage width="100%" height="50px" />);
        } else if (source === 'Quickbooks Online') {
            bankStatementSourceImages.push(<Quickbooks width="100%" height="50px" />);
        } else if (source === 'Spike') {
            bankStatementSourceImages.push(<PDF width="100%" height="50px" />);
        } else if (source === 'Yodlee') {
            bankStatementSourceImages.push(<Yodlee width="100%" height="50px" />);
        }
    }

    let image = <PDF width="60%" height="100%" />;

    if (props.fraudData.AccountingPackageData.package === 'Xero') {
        image = <Xero width="100%" height="100%" />;
    } else if (props.fraudData.AccountingPackageData.package === 'Sage One') {
        image = <Sage width="100%" height="100%" />;
    } else if (props.fraudData.AccountingPackageData.package === 'Quickbooks Online') {
        image = <Quickbooks width="100%" height="100%" />;
    } else if (props.fraudData.AccountingPackageData.package === 'Yodlee') {
        image = <Yodlee width="100%" height="100%" />;
    }

    return (
        <Card>
            <CardHeader color="info" stats icon>
                <CardIcon color="info">
                    <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory}>Fraud Data</p>
                <h3 className={classes.cardTitle}>{props.companyName}</h3>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={5} md={5} justify="center" direction="column" alignItems="center" container>
                        {image}
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7}>
                        <Table
                            tableData={[
                                ['Accounting Package', props.fraudData.AccountingPackageData.package],
                                [
                                    'Creation Date',
                                    _.isNil(props.fraudData.AccountingPackageData.createdDate)
                                        ? 'NA'
                                        : dateFns.isValid(props.fraudData.AccountingPackageData.createdDate)
                                        ? dateFns.format(
                                              dateFns.parseISO(props.fraudData.AccountingPackageData.createdDate),
                                              'MMM yyyy'
                                          )
                                        : 'NA'
                                ],
                                [
                                    'Applicant Active Director',
                                    props.fraudData.ApplicantActiveDirector === true ? (
                                        <CheckCircleIcon style={{ color: green[500] }} />
                                    ) : (
                                        <CancelIcon color="error" />
                                    )
                                ],
                                [
                                    'Bank Statement Sources',
                                    <GridContainer>
                                        {bankStatementSourceImages.map((image, index) => (
                                            <GridItem key={index} xs={12} sm={4} md={4}>
                                                {image}
                                            </GridItem>
                                        ))}
                                    </GridContainer>
                                ]
                            ]}
                        ></Table>
                    </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter />
        </Card>
    );
};

export default FraudDataComponent;

interface IProps {
    fraudData: IFraudDataDashboard;
    companyName: string;
}

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import React, { useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import logo from '../assets/img/bridgement.svg';
import styles from '../assets/jss/material-dashboard-react/layouts/adminStyle';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbars/Navbar';
import Sidebar from '../components/Sidebar/Sidebar';
import routes from '../routes';

let ps: any;

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            if (prop.layout === '/admin') {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            }
            return null;
        })}
        <Redirect from="/admin" to="/admin/overview" />
    </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
    // styles
    const classes = useStyles() as any;

    const mainPanel = useRef<HTMLDivElement>(null);

    const [color] = React.useState<any>('blue');

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const getRoute = () => {
        return window.location.pathname !== '/admin/maps';
    };
    const resizeFunction = () => {
        if (window.innerWidth >= 960) {
            setMobileOpen(false);
        }
    };

    // initialize and destroy the PerfectScrollbar plugin
    React.useEffect(() => {
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(mainPanel.current as HTMLElement, {
                suppressScrollX: true,
                suppressScrollY: false
            });
            document.body.style.overflow = 'hidden';
        }
        window.addEventListener('resize', resizeFunction);

        return function cleanup() {
            if (navigator.platform.indexOf('Win') > -1) {
                ps.destroy();
            }
            window.removeEventListener('resize', resizeFunction);
        };
    }, [mainPanel]);
    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                logoText={'Bridgement'}
                logo={logo}
                // image={image}
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
                color={color}
                {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
                <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />
                {getRoute() ? (
                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                ) : (
                    <div className={classes.map}>{switchRoutes}</div>
                )}
                {getRoute() ? <Footer /> : null}
            </div>
        </div>
    );
}

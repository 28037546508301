import { Grid, makeStyles } from '@material-ui/core';
import * as dateFns from 'date-fns';
import React from 'react';
import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle';
import { IDirectorAccountConfig } from '../../../constants/types';
import { IDirectorInformation } from '../../../models/OverviewModel';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardHeader from '../../Card/CardHeader';
import ExpansionTableRow from '../../Expansion/ExpansionTableRow';

const useStyles = makeStyles(styles);

const isFounder = (directorStartDate: string, companyStartDate: Date) => {
    let isFounder: boolean | string = false;

    const parsedDate = dateFns.parse(directorStartDate, 'dd-MM-yyyy', new Date());

    const isBefore = dateFns.isBefore(parsedDate, companyStartDate);

    if (isBefore) {
        isFounder = true;
    }

    return isFounder;
};

const DirectorInformationComponent: React.FC<IProps> = (props) => {
    const classes = useStyles();

    const directorInformation = props.directorInformation
        .sort(
            (a, b) =>
                Number(isFounder(b.StartDate, props.companyStartDate)) -
                Number(isFounder(a.StartDate, props.companyStartDate))
        )
        .sort((a, b) => Number(b.IsApplicant) - Number(a.IsApplicant))
        .sort((a, b) => Number(b.Status === 'ACTIVE') - Number(a.Status === 'ACTIVE'));

    return (
        <Card>
            <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Individual Information</h4>
            </CardHeader>
            <CardBody>
                <Grid container style={{ width: '100%', padding: '0 60px 0 24px' }}>
                    <Grid item md={3}>
                        Name
                    </Grid>
                    <Grid item md={1}>
                        Age
                    </Grid>
                    <Grid item md={1}>
                        Score
                    </Grid>
                    <Grid item md={1}>
                        Status
                    </Grid>
                    <Grid item md={1}>
                        Properties
                    </Grid>
                    <Grid item md={1}>
                        Red Flags
                    </Grid>
                    <Grid item md={1}>
                        Balance Exposure
                    </Grid>
                    <Grid item md={1}>
                        Monthly Instalment
                    </Grid>
                    <Grid item md={1}>
                        Cumulative Arrears
                    </Grid>
                    <Grid item md={1}>
                        Enquiries
                    </Grid>
                </Grid>
                {directorInformation.map((director, index) => (
                    <ExpansionTableRow
                        key={index}
                        companyStartDate={props.companyStartDate}
                        directorInformation={{
                            ...director,
                            IsFounder: isFounder(director.StartDate, props.companyStartDate)
                        }}
                        accountConfig={props.accountConfig}
                    />
                ))}
            </CardBody>
        </Card>
    );
};

export default DirectorInformationComponent;

interface IProps {
    directorInformation: IDirectorInformation[];
    companyStartDate: Date;
    accountConfig: IDirectorAccountConfig;
}

import React from 'react';

import Icon from '@material-ui/core/Icon';

import DirectorTimeline from '../../Graph/DirectorTimeline/DirectorTimeline';

import IconListExpansion from '../../Expansion/IconListExpansion';
import { IDirectorInformation } from '../../../models/OverviewModel';
import DirectorChanges from '../DirectorChanges/DirectorChanges';

const DirectorTimelinesComponent: React.FC<IProps> = (props) => {
    return (
        <IconListExpansion
            expansionList={[
                <DirectorTimeline
                    key="director_timeline"
                    directors={props.directorInformation}
                    minimumDate={props.companyStartDate}
                ></DirectorTimeline>
            ]}
            title={
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingRight: 5
                        }}
                    >
                        <Icon style={{ fontSize: 20, marginRight: 10 }}>info_outline</Icon> Director Timelines
                    </div>
                    <DirectorChanges
                        information={props.directorInformation}
                        directorStatusThreshold={6}
                        display="DIRECTOR"
                        companyThreshold={12}
                    />
                </>
            }
        ></IconListExpansion>
    );
};

export default DirectorTimelinesComponent;

interface IProps {
    directorInformation: IDirectorInformation[];
    companyStartDate: Date;
}

import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React from 'react';
import styles from '../../assets/jss/material-dashboard-react/components/headerStyle';
import Button from '../CustomButtons/Button';

const useStyles = makeStyles(styles);

export default function Header(props: IProps) {
    const classes = useStyles();

    function makeBrand() {
        var name;
        props.routes.map((prop) => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                name = props.rtlActive ? prop.rtlName : prop.name;
            }
            return null;
        });
        return name;
    }

    const { color } = props;

    const appBarClasses = classNames({
        [' ' + classes[color ? color : '']]: color
    });

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    <Button color="transparent" href="#" className={classes.title}>
                        {makeBrand()}
                    </Button>
                </div>
                <Hidden mdUp implementation="css">
                    <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle}>
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

interface IProps {
    color?: 'primary' | 'info' | 'success' | 'warning' | 'danger';
    rtlActive?: boolean;
    handleDrawerToggle?: any;
    routes: any[];
}

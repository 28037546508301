import React, { ReactNode } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons

// core components
import styles from '../../assets/jss/material-dashboard-react/components/cardHeaderStyle';

const useStyles = makeStyles(styles);

export default function CardHeader(props: IProps) {
	const classes = useStyles();
	const { className, children, color, plain, stats, icon, ...rest } = props;
	const cardHeaderClasses = classNames({
		[classes.cardHeader]: true,
		[classes[color + 'CardHeader']]: color,
		[classes.cardHeaderPlain]: plain,
		[classes.cardHeaderStats]: stats,
		[classes.cardHeaderIcon]: icon,
		[className]: className !== undefined
	});
	return (
		<div className={cardHeaderClasses} {...rest}>
			{children}
		</div>
	);
}

interface IProps {
	className?: any;
	color?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose';
	plain?: boolean;
	stats?: boolean;
	icon?: boolean;
	children?: ReactNode;
}

import React from 'react';
import './ExpansionTableRow.css';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const IconListExpansion: React.FunctionComponent<IProps> = (props) => {
    return (
        <ExpansionPanel>
            <ExpansionPanelSummary
                className="no-margin"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {props.title}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {props.expansionList.length === 0 ? 'No items to display' : props.expansionList}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default IconListExpansion;

interface IProps {
    expansionList: JSX.Element[];
    title: string | JSX.Element;
}

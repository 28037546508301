import * as dateFns from 'date-fns';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import './App.css';
import useAxios from './helpers/useAxios';
import Admin from './layouts/Admin';

const App: React.FC = () => {
    const [, setCookie] = useCookies(['authToken']);

    useAxios({
        url: `/api/xsrfToken`
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const auth = urlParams.get('auth');

        if (_.isNil(auth)) {
            return;
        }

        const expiration = dateFns.addSeconds(new Date(), 1800);

        setCookie('authToken', auth, { path: '/', expires: expiration });

        urlParams.delete('auth');
    }, [setCookie]);

    return (
        <RecoilRoot>
            <Router>
                <Switch>
                    <Route path="/admin" component={Admin} />
                    <Route
                        path="/"
                        component={({ location }: any) => (
                            <Redirect
                                to={{
                                    ...location,
                                    pathname: location.pathname.replace('/', '/admin/overview')
                                }}
                            />
                        )}
                    />
                </Switch>
            </Router>
        </RecoilRoot>
    );
};

export default App;

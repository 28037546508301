import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AxiosRequestConfig } from 'axios';
import * as dateFns from 'date-fns';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { assessmentDateAtom } from '../../atoms';
import ApplicantFraudExpansion from '../../components/Dashboard/ApplicantInformation/ApplicantFraudExpansion';
import CompanyCreditComponent from '../../components/Dashboard/CompanyCreditData/CompanyCreditComponent';
import CompanyJudgementsComponent from '../../components/Dashboard/CompanyJudgements/CompanyJudgementsComponent';
import CreditHistoryAnnotationComponent from '../../components/Dashboard/CreditHistoryAnnotation/CreditHistoryAnnotationComponent';
import DirectorInformationComponent from '../../components/Dashboard/DirectorInformation/DirectorInformationComponent';
import DirectorRelatedCompanyTimelinesComponent from '../../components/Dashboard/DirectorRelatedCompanyTimelines/DirectorRelatedCompanyTimelinesComponent';
import DirectorTimelinesComponent from '../../components/Dashboard/DirectorTimelines/DirectorTimelinesComponent';
import FraudDataComponent from '../../components/Dashboard/FraudData/FraudDataComponent';
import OverviewComponent from '../../components/Dashboard/Overview/OverviewComponent';
import PreviousEnquiriesComponent from '../../components/Dashboard/PreviousEnquiries/PreviousEnquiriesComponent';
import UnknownCreditReportKeysComponent from '../../components/Dashboard/UnknownCreditReportKeys/UnknownCreditReportKeysComponent';
import CounterPartyExpansion from '../../components/Expansion/CounterPartyExpansion';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Loading from '../../components/Loading/Loading';
import { IDateOption, IDirectorAccountConfig, IInputDateOptions, IUnknownKeyAudit } from '../../constants/types';
import useAxios from '../../helpers/useAxios';
import { IDashboardResult } from '../../models/OverviewModel';

const Overview: React.FC = () => {
    const [selectedDate, setSelectedDate] = useRecoilState(assessmentDateAtom);

    const [companyStartDate, setCompanyStartDate] = useState<Date>(new Date());
    const [assessmentExpired, setAssessmentExpired] = useState('');
    const [unknownKeyPresent, setUnknownKeyPresent] = useState({ message: '', keyCount: 0 });

    const [{ data: datesData, loading: datesLoading }] = useAxios<IInputDateOptions[]>({
        url: `/dashboard/organization/assessment-dates`
    });

    const [{ data: overviewData, loading: overviewLoading, error: overviewError }, executeOverviewRequest] =
        useAxios<IDashboardResult>(
            {
                method: 'POST',
                url: `/dashboard/organization/overview`
            },
            { manual: true }
        );
    const link = overviewData?.dynamicData.accountSharingGraphUrl

    const fetchDashboardData = useCallback(
        (data: AxiosRequestConfig['data']) => {
            executeOverviewRequest({
                data
            });
        },
        [executeOverviewRequest]
    );

    const [{ data: unknownKeyAuditData, loading: unknownKeyAuditLoading }, executeUnknownKeyAuditRequest] = useAxios<
        IUnknownKeyAudit[]
    >(
        {
            method: 'POST',
            url: `/dashboard/organization/audit-unknown-keys`
        },
        { manual: true }
    );

    const fetchAuditData = useCallback(
        (data: AxiosRequestConfig['data']) => {
            executeUnknownKeyAuditRequest({
                data
            });
        },
        [executeUnknownKeyAuditRequest]
    );

    useEffect(() => {
        if (selectedDate?.value) {
            fetchDashboardData({ AssessmentId: selectedDate.value });
        }
    }, [selectedDate, fetchDashboardData]);

    const options: IDateOption[] = useMemo(() => {
        if (datesData && datesData.length > 0) {
            const dates = datesData.map(({ id, date }: { id: number; date: string }) => ({
                value: id,
                label: date
            }));
            setSelectedDate(dates[0]);
            return dates;
        } else {
            return [];
        }
    }, [datesData, setSelectedDate]);

    const [{ data: accountConfig }] = useAxios<IDirectorAccountConfig>({
        url: `/dashboard/organization/director-account-config`
    });

    const overviewDashboard = overviewData?.assessmentData?.OverviewDashboard;

    const juristicEntitySummary = overviewData?.juristicEntitySummary;

    useEffect(() => {
        if (overviewDashboard && juristicEntitySummary) {
            setCompanyStartDate(dateFns.parseISO(overviewDashboard.RegisteredDate));

            if (
                !isNil(juristicEntitySummary.latestCreditReport) &&
                dateFns.differenceInDays(
                    new Date(juristicEntitySummary.latestCreditReport),
                    new Date(overviewDashboard.UpdatedOn)
                ) > 0
            ) {
                setAssessmentExpired('The credit report used to generate this assessment is outdated');
            } else if (dateFns.differenceInDays(new Date(), new Date(overviewDashboard.UpdatedOn)) > 30) {
                setAssessmentExpired('This assessment is more than 30 days old');
            }
        }
    }, [overviewDashboard, juristicEntitySummary]);

    useEffect(() => {
        const creditReportIds = overviewData?.assessmentData?.OverviewDashboard?.CreditReportIds;
        fetchAuditData(creditReportIds || []);
    }, [overviewData, fetchAuditData]);

    let keyCount = 0;

    if (unknownKeyAuditData && unknownKeyAuditData.length > 0) {
        keyCount = unknownKeyAuditData.map((item) => item.entries).flat(1).length;
    }

    useEffect(() => {
        if (unknownKeyAuditData && unknownKeyAuditData.length > 0) {
            setUnknownKeyPresent({
                message: `We discovered ${keyCount} new key${keyCount === 1 ? '' : 's'
                    } in the respective credit report(s) used to generate this assessment`,
                keyCount
            });
        }
    }, [unknownKeyAuditData, keyCount]);

    if (overviewError) {
        return <div>Error</div>;
    }

    const companyCreditDashboard = overviewData?.assessmentData?.CompanyCreditDashboard;

    const adverseInformation = companyCreditDashboard?.AdverseInformation || [];

    const sasfaInfo = companyCreditDashboard?.SasfaInfo || [];

    const enquiries = companyCreditDashboard?.Enquiries || [];

    const directorInformation = overviewData?.assessmentData?.DirectorInformation || [];

    const canShowCreditTimelines =
        overviewDashboard &&
        companyCreditDashboard?.CompanyCreditHistory &&
        companyCreditDashboard.CompanyCreditHistory.length > 0 &&
        directorInformation.length > 0 &&
        directorInformation[0]?.DirectorCreditHistory &&
        directorInformation[0].DirectorCreditHistory.length > 0;

    return overviewLoading || datesLoading || unknownKeyAuditLoading ? (
        <div style={{ textAlign: 'center' }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Loading />
                </GridItem>
            </GridContainer>
        </div>
    ) : isNil(overviewData) ? (
        <div>Error</div>
    ) : (
        <div>
            <Snackbar
                open={!!assessmentExpired}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setAssessmentExpired('')}
            >
                <Alert onClose={() => setAssessmentExpired('')} severity="warning">
                    {assessmentExpired}
                </Alert>
            </Snackbar>
            <Snackbar
                open={!!unknownKeyPresent.message}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setUnknownKeyPresent({ message: '', keyCount: 0 })}
            >
                <Alert onClose={() => setUnknownKeyPresent({ message: '', keyCount: 0 })} severity="error">
                    {unknownKeyPresent.message}
                </Alert>
            </Snackbar>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6} padding="0 15px !important">
                    <GridContainer>
                        {overviewDashboard && (
                            <>
                                <GridItem xs={12} sm={12} md={12}>
                                    <OverviewComponent
                                        overview={overviewDashboard}
                                        latestCreditReport={juristicEntitySummary.latestCreditReport}
                                        dateOptions={options}
                                    />
                                </GridItem>
                                {companyCreditDashboard && (
                                    <GridItem padding="0 15px !important" xs={12} sm={12} md={12}>
                                        <CompanyCreditComponent
                                            companyCredit={companyCreditDashboard}
                                            companyName={overviewDashboard.CompanyName}
                                        />
                                    </GridItem>
                                )}
                            </>
                        )}
                    </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={6} padding="0 15px !important">
                    <GridContainer>
                        {overviewDashboard && (
                            <GridItem xs={12} sm={12} md={12}>
                                <FraudDataComponent
                                    fraudData={overviewData.assessmentData.FraudDataDashboard}
                                    companyName={overviewDashboard.CompanyName}
                                />
                            </GridItem>
                        )}
                        <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                            <CounterPartyExpansion
                                counterPartyRiskInformation={overviewData.assessmentData.CounterPartyRiskDashboard}
                                link={link}
                            ></CounterPartyExpansion>
                        </GridItem>

                        {overviewData.assessmentData.ApplicantDashboard && (
                            <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                                <ApplicantFraudExpansion
                                    applicantFraudData={overviewData.assessmentData.ApplicantDashboard}
                                    directorInformation={directorInformation}
                                ></ApplicantFraudExpansion>
                            </GridItem>
                        )}

                        <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                            <CompanyJudgementsComponent
                                adverseInformation={adverseInformation}
                                sasfaInformation={sasfaInfo}
                            />
                        </GridItem>

                        <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                            <PreviousEnquiriesComponent enquiries={enquiries} />
                        </GridItem>

                        <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                            <DirectorTimelinesComponent
                                directorInformation={directorInformation}
                                companyStartDate={companyStartDate}
                            />
                        </GridItem>

                        <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                            <DirectorRelatedCompanyTimelinesComponent
                                directorInformation={directorInformation}
                                companyStartDate={companyStartDate}
                            />
                        </GridItem>

                        {canShowCreditTimelines && (
                            <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                                <CreditHistoryAnnotationComponent
                                    directorInformation={directorInformation}
                                    companyCreditHistory={companyCreditDashboard.CompanyCreditHistory}
                                    companyName={overviewDashboard.CompanyName}
                                />
                            </GridItem>
                        )}

                        {unknownKeyAuditData && unknownKeyAuditData.length > 0 && (
                            <GridItem padding="10px 15px !important" xs={12} sm={12} md={12}>
                                <UnknownCreditReportKeysComponent
                                    unknownKeys={unknownKeyAuditData}
                                    keyCount={keyCount}
                                />
                            </GridItem>
                        )}
                    </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} padding="0 15px !important">
                    <DirectorInformationComponent
                        companyStartDate={companyStartDate}
                        directorInformation={directorInformation}
                        accountConfig={accountConfig}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default Overview;

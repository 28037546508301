/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://cwxfe762drebvotjqqglrv3lmi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:04b4713c-81ae-405e-b117-e74b7e1c8d33",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_7mUKlT55r",
    "aws_user_pools_web_client_id": "73ri518cqmhpi4p05toa8f5ole",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "nalytics-20191025125749-hostingbucket-production",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://nalytics-20191025125749-hostingbucket-production.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;

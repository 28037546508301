import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as dateFns from 'date-fns';
import { isNil } from 'lodash';
import React from 'react';
import Select, { SingleValue } from 'react-select';
import { useRecoilState } from 'recoil';
import styles from '../../../assets/jss/material-dashboard-react/views/dashboardStyle';
import { assessmentDateAtom } from '../../../atoms';
import { IDateOption } from '../../../constants/types';
import formatCurrency from '../../../helpers/currencyFormatHelper';
import { IOverviewDashboard } from '../../../models/OverviewModel';
import Card from '../../Card/Card';
import CardBody from '../../Card/CardBody';
import CardFooter from '../../Card/CardFooter';
import CardHeader from '../../Card/CardHeader';
import CardIcon from '../../Card/CardIcon';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Table from '../../Table/Table';

const useStyles = makeStyles(styles);

const OverviewComponent: React.FC<IProps> = (props) => {
    const [selectedOption, setSelectionOption] = useRecoilState(assessmentDateAtom);

    const classes = useStyles();

    const statedRevenue = formatCurrency(parseFloat(props.overview.StatedRevenue));

    const requestedAmount = formatCurrency(parseFloat(props.overview.AmountRequested));

    return (
        <Card>
            <CardHeader color="info" stats icon>
                <CardIcon color="info">
                    <Icon>content_copy</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Overview</p>
                <h3 className={classes.cardTitle}>{props.overview.CompanyName}</h3>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                        <Table
                            tableData={[
                                ['Registration Number', props.overview.RegistrationNumber],
                                ['Company Type', props.overview.CompanyType],
                                [
                                    'Company Start Date',
                                    dateFns.format(dateFns.parseISO(props.overview.StartDate), 'MMM yyyy')
                                ],
                                ['Stated Revenue', statedRevenue],
                                ['Amount Requested', requestedAmount],
                                ['Location', props.overview.Location],
                                ['Referral Source', props.overview.ReferralSource],
                                ['Service Representative', props.overview.ServiceRepresentative]
                            ]}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                        <div style={{ marginTop: 30 }}>
                            <span style={{ fontWeight: 'bold', fontSize: 13 }}>Select Assessment</span>
                        </div>
                        <Select
                            value={selectedOption}
                            options={props?.dateOptions || []}
                            onChange={(newValue: SingleValue<IDateOption>) => setSelectionOption(newValue)}
                            isDisabled={
                                isNil(props?.dateOptions) || (props?.dateOptions && props.dateOptions.length <= 1)
                            }
                        />
                        <Table
                            tableData={[
                                [
                                    'Latest Credit Report',
                                    props?.latestCreditReport
                                        ? dateFns.format(new Date(props.latestCreditReport), 'dd-MM-yyyy')
                                        : ''
                                ],
                                [
                                    'Valid',
                                    props?.latestCreditReport ? (
                                        dateFns.differenceInDays(
                                            new Date(props?.latestCreditReport),
                                            new Date(props.overview.UpdatedOn)
                                        ) > 0 ? (
                                            <CancelIcon color="error" />
                                        ) : (
                                            <CheckCircleIcon style={{ color: green[500] }} />
                                        )
                                    ) : (
                                        ''
                                    )
                                ],

                                ['UTM Source', props.overview.UTMSource],
                                ['UTM Campaign', props.overview.UTMCampaign],
                                ['UTM Medium', props.overview.UTMMedium],
                                ['UTM Term', props.overview.UTMTerm],
                                ['How Did You Find Us', props.overview.HowDidYouFindUs]
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
            <CardFooter />
        </Card>
    );
};

export default OverviewComponent;

interface IProps {
    overview: IOverviewDashboard;
    latestCreditReport?: Date;
    dateOptions?: IDateOption[];
}

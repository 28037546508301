import { useRef, useEffect } from 'react';
import { Gauge, cubicBezier, Config } from 'gauge-chart-js';
import tinygradient from 'tinygradient';

import _ from 'lodash';

const useGauge = (valuePercentage: number) => {
    const gaugeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!_.isNil(gaugeRef.current)) {
            const fromAngle = 220;
            const toAngle = 500;
            const maxValue = toAngle - fromAngle;
            const value = (maxValue * valuePercentage) / 100;

            const sharedConfig: Config = {
                lineWidth: 2,
                container: gaugeRef.current,
                fromAngle,
                toAngle,
                value: maxValue,
                gaugeRadius: 35,
                easing: cubicBezier(0.165, 0.84, 0.44, 1)
            };

            const gaugeBackground = new Gauge({
                ...sharedConfig,
                color: '#f5f5f5'
            });
            gaugeBackground.setValue(maxValue);

            const gradient = tinygradient([
                { color: '#d13425', pos: 0 },
                { pos: 0.6428571429 },
                { color: '#db5d3d', pos: 0.6457142857 },
                { pos: 0.6857142857 },
                { color: '#ffb32f', pos: 0.6885714286 },
                { pos: 0.7257142857 },
                { color: '#71c07c', pos: 0.7285714286 },
                { pos: 0.76 },
                { color: '#42a257', pos: 0.7628571429 }
            ]);
            const gradientArray = gradient.rgb(300);
            gradientArray.map((color) => {
                return `#${color.toHex()}`;
            });

            const color = gradient.rgbAt(valuePercentage / 100).toHex();

            const gaugeMain = new Gauge({
                ...sharedConfig,
                color: `#${color}`
            });
            gaugeMain.setValue(value);
        }
    }, [valuePercentage]);

    return gaugeRef;
};

export default useGauge;
